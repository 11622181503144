import {
    RESTAURANT_ADD,
    RESTAURANT_REMOVE_ONE,
    RESTAURANT_UPDATE,
    RESTAURANTS_LOAD,
    RESTAURANTS_SET_ACTIVE
} from '../types';
import {updateObjectInArray} from "../../utils/helpers";

export const defaultState = {
    restaurants: [],
    activeRestaurant: {},
};

export function restaurantReducer(state = defaultState, action) {
    switch (action.type) {
        case RESTAURANT_REMOVE_ONE:
            const restaurantsFiltered = state.restaurants.filter((item, index) => { return item.id !== action.payload.restaurantId });
            const newActiveRestaurant = (restaurantsFiltered.length ? restaurantsFiltered[0] : {})
            return {
                ...state,
                restaurants: restaurantsFiltered,
                activeRestaurant: newActiveRestaurant,
            }
        case RESTAURANT_ADD:
            console.log('RESTAURANT_ADD', action.payload);
            return {
                ...state,
                restaurants: [...state.restaurants, action.payload.restaurant],
            }
        case RESTAURANTS_LOAD:
            return {
                ...state,
                restaurants: [...action.payload],
                activeRestaurant: {...action.payload[0]}
            }
        case RESTAURANTS_SET_ACTIVE:
            return {
                ...state,
                activeRestaurant: {...action.payload},
            }
        case RESTAURANT_UPDATE:
            const {id, title, slug} = action.payload.restaurant.data;
            let restaurants = state.restaurants;
            let restaurantToUpdate = restaurants.filter(restaurant => restaurant.id === +id);
            const objIndex = restaurants.findIndex((obj => obj.id == restaurantToUpdate[0].id));
            restaurants[objIndex] = {
                id: +id,
                title,
                slug
            };
            return {
                ...state,
                restaurants: restaurants
            }
    }

    return state;
}
