import React from "react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import ValidationHint from "../ValidationHint/ValidationHint";
import Button from "../base-components/Button";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

function EditRestaurantForm({onSubmit, handleRestaurantName, restaurantName}) {
    const {register, formState: { errors }, handleSubmit,} = useForm({
        mode: "onBlur", // "onChange",
        defaultValues: {
            'restaurant_name': restaurantName
        }
    });
    const {t} = useTranslation('common');
    const history = useHistory();
    const classesRestaurantName = classNames(
        'form-field',
        {
            'form-field--error': errors.restaurant_name
        }
    );
    const goBack = e => {
        e.preventDefault();
        history.goBack();
    };
    return (
        <form className='edit-restaurant' method="post" onSubmit={handleSubmit(onSubmit)}>
            <section className='dashboard-section'>
                <div className="form__row">
                    <label htmlFor="restaurant_name">{t('restaurant.form.label')}</label>
                    <input {...register("restaurant_name", { required: t('error.validation.required') })}
                           type="text"
                           name="restaurant_name"
                           className={classesRestaurantName}
                           label="Название"
                           id="restaurant_name"
                           placeholder={t('restaurant.form.placeholder')}
                           onChange={ (value) => handleRestaurantName( value ) }
                    />
                    <ValidationHint validationError={errors.restaurant_name}/>
                </div>
            </section>
            <section className='dashboard-section'>
                <h2 className='dashboard-section__title dashboard-section__title--plan'>{t('restaurant.typeOfPlan')}</h2>
                <p>{t('restaurant.freePlan')}</p>
            </section>
            <div className="edit-restaurent-buttons">
                <a className='button btn-sm btn-transparent' transparent onClick={goBack}>{t('cancel')}</a>
                <Button small onClick={()=>{}}>{t('save')}</Button>
            </div>
        </form>
    );
}

export default EditRestaurantForm;
