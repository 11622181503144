import React from 'react';
import {withRouter} from "react-router-dom";
import {loginUser} from "../../services/loginService/login.service";
import {registerUser} from "../../services/registerService/register.service";
import ErrorInfoBox from "../ErrorInfoBox/ErrorInfoBox";
import {loadUserData} from "../../store/user/actions";
import {connect} from "react-redux";
import { withTranslation } from 'react-i18next';
import RegisterForm from "./RegisterForm";

class Register extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { error: false }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = async (userData) => {
        const {username, email, password} = userData;

        const registerResponse = await registerUser({
            username: username,
            password: password,
            email: email
        });
        if(registerResponse.status === 200) {
            const loginResponse = await loginUser({
                username: email,
                password: password,
            });

            if(loginResponse.status === 200) {
                this.props.loadUserData(registerResponse.data.userData);
                this.props.history.push("/");
            } else {
                this.setState( {error: loginResponse.error});
            }
        } else {
            this.setState( {error: registerResponse.error});
        }
    }
    render() {
        const { t } = this.props;
        return (
            <div className='login-page'>
                <div className='signin-section-wrapper'>
                    <div className='signin-section'>
                        <div className='form-container'>
                            <h1 className='form-caption'>Регистрация</h1>
                            <RegisterForm onSubmit={this.handleSubmit}/>
                        </div>
                        <div className='signin-info-container'>
                            <h3 className='signin-info-container__logo'>
                                <svg width="166" height="32" viewBox="0 0 166 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M44 6L28 6C22.4772 6 18 10.4772 18 16C18 21.5228 22.4772 26 28 26L44 26C49.5228 26 54 21.5228 54 16C54 10.4772 49.5228 6 44 6ZM12 16C12 24.8366 19.1634 32 28 32L44 32C52.8366 32 60 24.8366 60 16C60 7.16345 52.8366 4.429e-07 44 3.47062e-07L28 1.73531e-07C19.1634 7.76924e-08 12 7.16344 12 16Z" fill="#4F4C60"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M28 26C33.5228 26 38 21.5228 38 16C38 10.4772 33.5228 6 28 6C22.4772 6 18 10.4772 18 16C18 21.5228 22.4772 26 28 26ZM12 16C12 24.8366 19.1634 32 28 32C36.8366 32 44 24.8366 44 16C44 7.16344 36.8366 2.69369e-07 28 1.73531e-07C19.1634 7.76924e-08 12 7.16344 12 16Z" fill="white"/>
                                    <path d="M66 32H72.8V21.1556H79.1887C79.9201 21.1556 80.5931 21.5548 80.9438 22.1966L86.3 32H94L88.5799 22.1048C88.005 21.0552 88.4945 19.7586 89.4844 19.0861C90.5558 18.3583 91.4027 17.4555 92.025 16.3778C93.0083 14.6889 93.5 12.7556 93.5 10.5778C93.5 7.88148 92.7583 5.58519 91.275 3.68889C89.7917 1.79259 87.4333 0.622223 84.2 0.177779C83.7333 0.103705 83.2 0.0592598 82.6 0.0444443C82.0167 0.014815 81.55 0 81.2 0H66V32ZM72.8 15.5111V5.64444H80.9C81.25 5.64444 81.6333 5.65926 82.05 5.68889C82.4667 5.71852 82.85 5.77778 83.2 5.86667C84.1167 6.08889 84.825 6.46667 85.325 7C85.825 7.51852 86.1667 8.1037 86.35 8.75556C86.55 9.39259 86.65 10 86.65 10.5778C86.65 11.1556 86.55 11.7704 86.35 12.4222C86.1667 13.0593 85.825 13.6444 85.325 14.1778C84.825 14.6963 84.1167 15.0667 83.2 15.2889C82.85 15.3778 82.4667 15.437 82.05 15.4667C81.6333 15.4963 81.25 15.5111 80.9 15.5111H72.8Z" fill="white"/>
                                    <path d="M100 32H106.8V21.1556H113.189C113.92 21.1556 114.593 21.5548 114.944 22.1966L120.3 32H128L122.58 22.1048C122.005 21.0552 122.494 19.7586 123.484 19.0861C124.556 18.3583 125.403 17.4555 126.025 16.3778C127.008 14.6889 127.5 12.7556 127.5 10.5778C127.5 7.88148 126.758 5.58519 125.275 3.68889C123.792 1.79259 121.433 0.622223 118.2 0.177779C117.733 0.103705 117.2 0.0592598 116.6 0.0444443C116.017 0.014815 115.55 0 115.2 0H100V32ZM106.8 15.5111V5.64444H114.9C115.25 5.64444 115.633 5.65926 116.05 5.68889C116.467 5.71852 116.85 5.77778 117.2 5.86667C118.117 6.08889 118.825 6.46667 119.325 7C119.825 7.51852 120.167 8.1037 120.35 8.75556C120.55 9.39259 120.65 10 120.65 10.5778C120.65 11.1556 120.55 11.7704 120.35 12.4222C120.167 13.0593 119.825 13.6444 119.325 14.1778C118.825 14.6963 118.117 15.0667 117.2 15.2889C116.85 15.3778 116.467 15.437 116.05 15.4667C115.633 15.4963 115.25 15.5111 114.9 15.5111H106.8Z" fill="white"/>
                                    <path d="M144.057 32H151.943V18.9778L166 0H157.922C157.288 0 156.692 0.300594 156.315 0.810193L149.608 9.87236C148.808 10.9526 147.192 10.9526 146.392 9.87236L139.685 0.810193C139.308 0.300594 138.712 0 138.078 0H130L144.057 18.9778V32Z" fill="white"/>
                                    <path d="M16 16C16 20.4183 12.4183 24 8 24C3.58172 24 0 20.4183 0 16C0 11.5817 3.58172 8 8 8C12.4183 8 16 11.5817 16 16Z" fill="#4F4C60"/>
                                </svg>
                            </h3>
                            <span className='signin-info-container__go-to signin-info-container__go-to--desktop'>{t('register.form.text.accountExist')} <a href="/login">{t('login')}</a></span>
                        </div>
                        <span className='signin-info-container__go-to signin-info-container__go-to--mobile'>{t('register.form.text.accountExist')} <a href="/login">{t('login')}</a></span>
                    </div>
                    {this.state.error && <ErrorInfoBox text={this.state.error}/>}
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({
    loadUserData: userData => {
        dispatch(loadUserData(userData));
    },
});

export default withTranslation('common')(withRouter(connect(null, mapDispatchToProps)(Register)));
