import ValidationHint from "../ValidationHint/ValidationHint";
import React from "react";
import {useForm} from "react-hook-form";
import classNames from "classnames";
import {useTranslation} from "react-i18next";

function RegisterForm({onSubmit}) {
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    });
    const {t} = useTranslation('common');

    const emailValidationPattern = {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: t('error.validation.wrongEmail')
    }

    const classesUsername = classNames(
        'form-field',
        {
            'form-field--error': errors.username
        }
    );

    const classesEmail = classNames(
        'form-field',
        {
            'form-field--error': errors.email
        }
    );

    const classesPassword = classNames(
        'form-field',
        {
            'form-field--error': errors.password
        }
    );

    return (
        <form className="register" method="post" onSubmit={handleSubmit(onSubmit)}>
            <div className='form__row'>
                <label htmlFor="username">{t('register.form.username.label')}</label>
                <input {...register("username", { required: t('error.validation.required') })}
                       className={classesUsername}
                       type='text'
                       name='username'
                       id='username'
                       placeholder={t('register.form.username.placeholder')}
                />
                <ValidationHint validationError={errors.username}/>
            </div>
            <div className='form__row'>
                <label htmlFor="email">{t('register.form.email.label')}</label>
                <input {...register("email", { required: t('error.validation.required'), pattern: emailValidationPattern })}
                       className={classesEmail}
                       type='text'
                       id='email'
                       name='email'
                       placeholder={t('register.form.email.placeholder')}
                />
                <ValidationHint validationError={errors.email}/>
            </div>
            <div className='form__row'>
                <label htmlFor="password">{t('register.form.password.label')}</label>
                <input {...register("password", { required: t('error.validation.required')})}
                       className={classesPassword}
                       type="password"
                       id='password'
                       name='password'
                       placeholder={t('register.form.password.placeholder')}
                />
                <ValidationHint validationError={errors.password}/>
            </div>
            <button>{t('register.form.button.label')}</button>
        </form>
    )
}

export default RegisterForm;
