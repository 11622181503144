import React, { useState, useEffect } from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import config from "./settings";
import './App.css';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import Register from "./components/Register/Register";

function App() {
  const [login, setLogin] = useState( '' );
  const siteURL = `${config.services.orryAPI.public}/`;

  useEffect(() => {
    const localLogin = localStorage.getItem('login');
    // If we have logged in, set it.
    if ( localLogin ) {
      setLogin( localLogin );
    }
  }, [login]);

  return (
      <div className="App container">
          <Router>
              <Switch>
                  <Route exact path="/">
                      <Redirect to="/dashboard" />
                  </Route>
                  <Route path={'/dashboard'}>
                    <Dashboard url={siteURL} token={login} />
                  </Route>
                  <Route path={'/login'}>
                    <Login url={siteURL} />
                  </Route>
                  <Route path={'/register'}>
                    <Register url={siteURL} />
                  </Route>
              </Switch>
          </Router>
      </div>
  );
}

export default App;
