import React from "react";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './HelpTooltip.css';
const HelpTooltip = ({content}) => {
    return (
        <Tippy content={content}
               placement="right-end"
               offset={[0, 8]}
               maxWidth={350}
               popperOptions={{
                   strategy: 'fixed',
                   modifiers: [
                       {
                           name: 'flip',
                           options: {
                               fallbackPlacements: ['bottom', 'left'],
                           },
                       },
                       {
                           name: 'preventOverflow',
                           options: {
                               altAxis: true,
                               tether: false,
                           },
                       },
                   ],
               }}>
                <span className='tooltip help-tooltip-icon'>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="16" height="16" rx="8" fill="#DBDBDB"/>
                        <rect x="7" y="7" width="2" height="5" rx="1" fill="white"/>
                        <rect x="7" y="4" width="2" height="2" rx="1" fill="white"/>
                    </svg>
                </span>
        </Tippy>

    );
};

export default HelpTooltip;
