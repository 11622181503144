import { createStore, applyMiddleware, compose } from 'redux';
import {combineReducers} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {restaurantReducer} from './restaurants/reducer';
import {menuReducer} from './menu/reducer';
import {userReducer} from './user/reducer';
import {generalReducer} from "./general/reducer";
import reduxThunk from 'redux-thunk';
import {RESET_STORE} from "./types";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig = {
    key: 'root',
    storage: storage,
    debug: true,
    blacklist: ['general']
};

const allReducers = combineReducers({
    restaurants: restaurantReducer,
    menu: menuReducer,
    user: userReducer,
    general: generalReducer
});
const rootReducer = (state, action) => {
    if (action.type === RESET_STORE) {
        state = undefined;
    }
    return allReducers(state, action);
}
const pReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(pReducer, composeEnhancers(applyMiddleware(reduxThunk)));
const persistor = persistStore(store);
export { store, persistor };
