import React from "react";
import classNames from "classnames";
import {setActiveCategoryId} from "../../store/menu/actions";
import {useModal} from "../../context/model-context";
import {useDispatch} from "react-redux";
import EditMenuCategoryForm from "../AddMenu/EditMenuCategoryForm";
function DishCategory({children, className, home, activeCategoryId, id}) {
    const { setModal } = useModal();
    const dispatch = useDispatch();
    const classesDishCategory = classNames(
        'dish-category',
        className,
        {
            'dish-category-home': home,
            'dish-category-active': activeCategoryId === id
        }
    );
    const handleActionBtn = (event) => {
        event.stopPropagation();
        console.log('handleActionBtn', id, children, home);
        setModal(<EditMenuCategoryForm id={id} categoryName={children} categoryHome={home}/>);
    }
    return (
        <div className={classesDishCategory} onClick={() => dispatch(setActiveCategoryId(id))} >
            {home && <span className='dish-category__home-icon'><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path d="M11.8562 5.37451L6.38471 0.149365C6.28244 0.0536258 6.14493 0 6.00171 0C5.85849 0 5.72099 0.0536258 5.61871 0.149365L0.147252 5.37451C0.0767344 5.44663 0.0290391 5.53634 0.00968514 5.63326C-0.00966881 5.73018 0.000109628 5.83036 0.0378891 5.92219C0.0756687 6.01403 0.139905 6.09378 0.223166 6.1522C0.306426 6.21063 0.405307 6.24534 0.508368 6.25234H1.17589V11.4775C1.17589 11.6161 1.23353 11.749 1.33614 11.847C1.43875 11.9449 1.57792 12 1.72303 12H4.78705V8.49392C4.78705 8.35534 4.84469 8.22244 4.9473 8.12445C5.04991 8.02646 5.18908 7.97141 5.33419 7.97141H6.65829C6.8034 7.97141 6.94257 8.02646 7.04518 8.12445C7.14779 8.22244 7.20543 8.35534 7.20543 8.49392V11.9948H10.2694C10.4146 11.9948 10.5537 11.9397 10.6563 11.8417C10.7589 11.7437 10.8166 11.6108 10.8166 11.4723V6.24711H11.5115C11.6121 6.23676 11.7077 6.19996 11.7878 6.1408C11.8678 6.08165 11.9291 6.00246 11.9648 5.91203C12.0005 5.82161 12.0093 5.7235 11.9901 5.6286C11.9709 5.53371 11.9246 5.44575 11.8562 5.37451Z" fill="#363347"/>
            </svg></span>}
            <span className='dish-category__name'>{children}</span>
            <span className='dish-category__action-btn' onClick={(e) => handleActionBtn(e)}>
                <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2" cy="2" r="2" transform="rotate(90 2 2)" fill="#C4C4C4"/>
                    <circle cx="2" cy="8" r="2" transform="rotate(90 2 8)" fill="#C4C4C4"/>
                    <circle cx="2" cy="14" r="2" transform="rotate(90 2 14)" fill="#C4C4C4"/>
                    <circle cx="2" cy="2" r="2" fill="#363347"/>
                    <circle cx="8" cy="2" r="2" fill="#363347"/>
                    <circle cx="14" cy="2" r="2" fill="#363347"/>
                </svg>
            </span>
        </div>
    );
}

export default DishCategory;
