import config from "../../settings";
const axios = require('axios');
const apiUrl = config.services.orryAPI.public;
export async function getMenu(restaurantId) {
    const token = localStorage.getItem('login');
    const menusURI = `${apiUrl}/wp-json/wp/v1/menus?restaurant_id=${restaurantId}`;
    const res = await axios({
        method: 'GET',
        url: menusURI,
        headers: { 'Authorization': 'Bearer ' + token }
    }).then(function (response) {
        if ( response.status === 200 ) {
            return response.data;
        }
    }).catch(function (error) {
        console.log('???????? catch ', error);
    });
    return res;
}

export async function saveMenu(menuData) {
    const token = localStorage.getItem('login');
    const menusURI = `${apiUrl}/wp-json/wp/v1/menus`;
    const method = (menuData.menu.postStatus === 'publish') ? 'PUT' : 'POST';
    const res = await axios({
        method: method,
        url: menusURI,
        headers: { 'Authorization': 'Bearer ' + token },
        data: menuData
    }).then(function (response) {
        if ( response.status === 200 || response.status === 201) {
            return response.data;
        }
    }).catch(function (error) {
        console.log('???????? catch ', error);
    });
    return res;
}
