import React, {useState} from "react";
import GoBack from "../GoBack/GoBack";
import AddRestaurantForm from "../AddRestaurant/AddRestaurantForm"
import {addRestaurant, setActiveRestaurant} from '../../store/restaurants/actions';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {useModal} from "../../context/model-context";
import InformForm from "../InformForm";
import {useTranslation} from "react-i18next";
import {createRestaurant} from "../../services/restaurantsService/restaurant.service";

function AddRestaurant({history, addRestaurant, setActiveRestaurant}) {
    const { setModal } = useModal();
    const [restaurantName, setRestaurantName] = useState('');
    // TODO show user an error somehow
    const [error, setError] = useState(false);
    const {t} = useTranslation('common');
    const handleRestaurantName = e => {
        setRestaurantName(e.target.value);
    }

    const handleSubmit = async () => {
        try {
            const restaurantData = await createRestaurant(restaurantName);

            addRestaurant(restaurantData);
            setModal(<InformForm
                title={t('restaurant.inform.title')}
                description={t('restaurant.inform.description')}
                buttonText={t('restaurant.inform.buttonText')}
                handleOkClick={() => {
                    setModal();
                    setActiveRestaurant(restaurantData)
                    history.push("/dashboard/menu/new");
                }}
            />);
            history.push("/dashboard");
        } catch (error) {
            const errorText = (error.response) ? error.response.data.message : error.message;
            setError(errorText);
        }
    }
    return (
        <div>
            <GoBack />
            <h1 className='dashboard__page-name'>{t('restaurant.form.title')}</h1>
            <AddRestaurantForm onSubmit={handleSubmit} handleRestaurantName={handleRestaurantName} />
        </div>
    );
}
const mapDispatchToProps = dispatch => ({
    addRestaurant: restaurant => {
        dispatch(addRestaurant(restaurant));
    },
    setActiveRestaurant: restaurant => {
        dispatch(setActiveRestaurant(restaurant))
    }
});

export default withRouter(
    connect(null, mapDispatchToProps)(AddRestaurant)
);
