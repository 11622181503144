import React, {useState, forwardRef, Component, useEffect} from "react";
import './DishCategories.css';
import Button from "../base-components/Button";
import DishCategory from "../DishCategory";
import AddMenuCategoryForm from "../AddMenu/AddMenuCategoryForm";
import { ReactSortable } from "react-sortablejs";
import {sortCategories} from "../../store/menu/actions";
import {useModal} from "../../context/model-context";
import {connect, useDispatch} from "react-redux";
import {get} from "lodash";
import {MENU_EDITED} from "../../store/types";

function DishCategories({categories, activeCategoryId}) {
    const dispatch = useDispatch();
    const { setModal } = useModal();
    return(
        <ReactSortable
           className='dish-categories'
           list={categories} setList={(newState) => {
               dispatch(sortCategories(newState));
           }}
           filter={".static"}
           onEnd={(event)=> {
                   let sortedList = categories;
                   let homeItemToInsert;
                   let buttonItemToInsert;
                   for (let i = sortedList.length; i--; ) {
                       if (sortedList[i].home === 1) {
                           homeItemToInsert = sortedList[i];
                           sortedList.splice(i, 1);
                       }
                       if (sortedList[i].hasOwnProperty('button')) {
                           buttonItemToInsert = sortedList[i];
                           sortedList.splice(i, 1);
                       }
                   }
                   sortedList.splice(0, 0, buttonItemToInsert);
                   if (typeof homeItemToInsert !== "undefined") {
                       sortedList.splice(1, 0, homeItemToInsert);
                   }
                   dispatch({type: MENU_EDITED});
               }
           }
        >
           {categories.map((item) => (
               item.button
                   ? (<Button
                       id='action'
                       className="add-dish-category static"
                       icon
                       rounded
                       key={item.id}
                       onClick={() => setModal(<AddMenuCategoryForm numberOfCategories={categories.length} />)}
                   >
                       <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <rect x="5" width="2" height="12" rx="1" fill="white"/>
                           <rect y="7" width="2" height="12" rx="1" transform="rotate(-90 0 7)" fill="white"/>
                       </svg>
                   </Button>)
                   : (<DishCategory
                       key={item.id}
                       id={item.id}
                       activeCategoryId={activeCategoryId}
                       home={item.home}
                       { ...( item.home && { className: 'static' } )}
                   >
                       {item.name}
                   </DishCategory>)

           ))}
        </ReactSortable>
    );
};
const mapStateToProps = state => {
    return {
        activeCategoryId: get(state,'menu.menu.activeCategoryId'),
        categories: get(state,'menu.menu.categories'),
    };
};
export default connect(mapStateToProps)(DishCategories);
