import { useState } from "react";

export const useInput = initialValue => {
    const [value, setValue] = useState(initialValue);
    return [
        { value, onChange: e => setValue(e.target.value) },
        () => setValue(initialValue)
    ];
};

export const useCheckbox = initialValue => {
    const [checked, setValue] = useState(initialValue);
    return [
        { checked, onChange: e => setValue(e.target.checked) },
        () => setValue(initialValue)
    ];
};
