import React, {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {useTranslation} from "react-i18next";
import imagePlaceholder from "./image-placeholder.svg";
import {imageResolutionValidation, validateImageFile} from "./UploadFileValidators";

export default function ImageDropZoneInput({chooseImg, setInvalidImgMessage, invalidInput}) {
    const [filePickerOpen, setFilePickerState] = useState(false);

    const validation = (file) => {
        return validateImageFile(file, setInvalidImgMessage)
    }

    const onDrop = useCallback((acceptedFiles) => {
        if(acceptedFiles?.length > 1) {
            setInvalidImgMessage('TOO_MANY_FILES')
            return;
        }

        if(acceptedFiles?.length) {
            const [uploadedFile] = acceptedFiles;

            const reader = new FileReader();
            reader.onload = (event) => {
                imageResolutionValidation(uploadedFile, chooseImg, setInvalidImgMessage, {
                    base64: event.target.result,
                    fileName: uploadedFile.name,
                    type: uploadedFile.type,
                    preview: URL.createObjectURL(uploadedFile)
                })
            };
            reader.readAsDataURL(uploadedFile);
        }
    }, []);

    const { getRootProps, getInputProps, open: openFilePickerDialog, isDragActive } = useDropzone({
        onDrop,
        noClick: true,
        validator: validation,
        onFileDialogOpen: () => setFilePickerState(false)
    });
    const {t} = useTranslation('common');

    useEffect(() => {
        if(filePickerOpen) {
            openFilePickerDialog();
            setFilePickerState(false)
        }
    }, [filePickerOpen])

    return (
        <>
            <div
                {...getRootProps()}
                className={`dish-dropzone ${isDragActive && 'active'} ${!!invalidInput && 'dish-dropzone--error'}`}
            >
                <input {...getInputProps()} />
                <span className='dish-dropzone-label'>
                    <img src={imagePlaceholder} alt="Image Placeholder Icon"/>
                        <div>
                            <span>
                                {t("menu.menu.popup.dishForm.image.placeholderDrag")}
                            </span>
                            <span className='dish-dropzone-label--upload' onClick={() => setFilePickerState(true)}>
                                {t("menu.menu.popup.dishForm.image.placeholderUpload")}
                            </span>
                        </div>
                </span>
            </div>
            <p className="dish-dropzone__description">{t("menu.menu.popup.dishForm.image.description")}</p>
        </>
    );
};

