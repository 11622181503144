import {
    RESTAURANT_ADD,
    RESTAURANT_REMOVE_ONE,
    RESTAURANTS_LOAD,
    RESTAURANTS_SET_ACTIVE,
    RESTAURANT_UPDATE,
    UPDATE_USER
} from '../types';

export const removeRestaurant = restaurantId => dispatch => {
    dispatch({
        type: RESTAURANT_REMOVE_ONE,
        payload: {restaurantId},
    });
};

export const addRestaurant = restaurant => dispatch => {
    dispatch({
        type: RESTAURANT_ADD,
        payload: {restaurant},
    });
};
export const updateRestaurant = restaurant => dispatch => {
    dispatch({
        type: RESTAURANT_UPDATE,
        payload: {restaurant},
    });
}
export const loadRestaurants = restaurants => dispatch => {
    dispatch({
        type: RESTAURANTS_LOAD,
        payload: restaurants.data,
    });
    dispatch({
        type: UPDATE_USER,
        payload: restaurants.userData,
    });
};
export const setActiveRestaurant = restaurant => dispatch => {
    dispatch({
        type: RESTAURANTS_SET_ACTIVE,
        payload: restaurant,
    });
};
