import React from "react";

const ToggleSection = ({title, children}) => {
    return (
        <div className='toggle-section'>
            <span className='toggle-section__label'>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7843 8.06177L9.57707 0.224047C9.42365 0.0804387 9.21739 0 9.00257 0C8.78774 0 8.58148 0.0804387 8.42806 0.224047L0.220879 8.06177C0.115102 8.16994 0.0435586 8.3045 0.0145277 8.44989C-0.0145032 8.59527 0.000164442 8.74554 0.0568337 8.88329C0.113503 9.02105 0.209858 9.14067 0.334748 9.2283C0.459639 9.31594 0.607961 9.36802 0.762553 9.37851H1.76383V17.2162C1.76383 17.4241 1.8503 17.6234 2.00421 17.7704C2.15813 17.9174 2.36688 18 2.58455 18H7.18057V12.7409C7.18057 12.533 7.26704 12.3337 7.42095 12.1867C7.57487 12.0397 7.78362 11.9571 8.00129 11.9571H9.98743C10.2051 11.9571 10.4138 12.0397 10.5678 12.1867C10.7217 12.3337 10.8081 12.533 10.8081 12.7409V17.9922H15.4042C15.6218 17.9922 15.8306 17.9096 15.9845 17.7626C16.1384 17.6156 16.2249 17.4163 16.2249 17.2084V9.37067H17.2672C17.4181 9.35514 17.5616 9.29994 17.6816 9.21121C17.8017 9.12247 17.8936 9.00369 17.9472 8.86805C18.0008 8.73241 18.0139 8.58524 17.9851 8.4429C17.9564 8.30056 17.8868 8.16863 17.7843 8.06177Z" fill="#396AFF"/>
                </svg>
                {title}
            </span>
            {children}
        </div>
    );
};

export default ToggleSection;
