import { useInput } from "../../hooks/hooks";
import React, {useState} from "react";
import Button from "../base-components/Button";
import HelpTooltip from "../base-components/HelpTooltip";
import ConfirmationForm from "../ConfirmationForm";
import {useModal} from "../../context/model-context";
import {updateCategory, deleteCategory} from "../../store/menu/actions";
import {useDispatch} from "react-redux";
import ToggleSection from "../base-components/Toggle/ToggleSection";
import Toggle from "../base-components/Toggle";
import {useTranslation} from "react-i18next";
export default function EditMenuCategoryForm({id, categoryName, categoryHome}) {
    const [name] = useInput(categoryName);
    const [homeChecked, setHome] = useState(categoryHome);
    const {t} = useTranslation('common');
    const dispatch = useDispatch();
    const { setModal } = useModal();
    const submit = e => {
        e.preventDefault();
        dispatch(updateCategory(id, name.value, homeChecked));
        setModal();
    };
    const isFormDisabled = () => {
        return !name.value;
    }
    return (
        <>
            <form className='modal-form' onSubmit={submit}>
                <h2 className="modal-form__title">{t('menu.menu.popup.editCategory.title')} <HelpTooltip content={<div><h4>{t('prompt')} </h4><p>{t('menu.menu.popup.editCategory.tooltip')}</p></div>} /></h2>
                <ToggleSection title={t('menu.menu.popup.editCategory.form.toggle')}>
                    <Toggle checked={homeChecked} onChange={() => setHome(!homeChecked)} disabled={false} />
                </ToggleSection>
                <div className="modal-form__fieldset">
                    <label htmlFor="restaurant_name">{t('menu.menu.popup.editCategory.form.label')}</label>
                    <input
                        {...name}
                        type="text"
                        placeholder={t('menu.menu.popup.editCategory.form.placeholder')}
                        required
                    />
                </div>
                <Button disabled={isFormDisabled()}>{t('menu.menu.popup.editCategory.form.button.text')}</Button>
                <Button
                    accent
                    onClick={() => setModal(
                        <ConfirmationForm
                            title={t('menu.menu.popup.editCategory.inform.title')}
                            handleCancelClick={() => setModal()}
                            handleOkClick={() => {
                                dispatch(deleteCategory(id));
                                setModal();
                            }}/>
                    )}
                >{t('menu.menu.popup.editCategory.form.button.deleteText')}</Button>
            </form>
        </>
    );
}
