import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {store, persistor} from './store/store';
import './index.css';
import App from './App';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_ua from "./translations/ua/common.json";
import common_en from "./translations/en/common.json";
import common_ru from "./translations/ru/common.json";
import reportWebVitals from './reportWebVitals';

WebFont.load({
    google: {
        families: ['Manrope:wght@200;300;400;500;600;700;800&display=swap', 'sans-serif']
    }
});

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'ua',
    resources: {
        en: {
            common: common_en
        },
        ru: {
            common: common_ru
        },
        ua: {
            common: common_ua
        },
    },
});
ReactDOM.render(
  <React.StrictMode>
      <I18nextProvider i18n={i18next}>
          <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <App />
              </PersistGate>
          </Provider>
      </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
