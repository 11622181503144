import React, {useEffect, useState, useCallback, useRef} from 'react';
import './ActionsButton.css';
import classNames from "classnames";

const ActionsButton = ({ children, fill, classes }) => {
    const [showPanel, setShowPanel] = useState(false);
    const node = useRef();

    const onClick = useCallback( (e) => {

        if (node && node.current && node.current.contains(e.target)) {
            if (e.target.classList.contains('actions-button')) {
                return;
            }
            if (e.target.type === 'button' || e.target.type === 'submit') {
                setShowPanel(false);
            }
            return;
        }
        // outside click
        setShowPanel(false);
    }, [])

    useEffect(() => {
        if (showPanel) {
            document.addEventListener('click', onClick);
        }
        return () => window.removeEventListener('click', onClick)
    }, [showPanel, onClick]);

    const classesActionWrapper = classNames(
        'actions-wrapper',
        classes
    );

    return (
        <div ref={node} className={classesActionWrapper}>
            <button className='actions-button' onClick={() => setShowPanel(!showPanel)}>
                <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2" cy="2" r="2" transform="rotate(90 2 2)" fill={fill}/>
                    <circle cx="2" cy="9" r="2" transform="rotate(90 2 9)" fill={fill}/>
                    <circle cx="2" cy="16" r="2" transform="rotate(90 2 16)" fill={fill}/>
                </svg>
            </button>
            {showPanel && <div className='actions-panel'>{children}</div>}
        </div>
    );
};

export default ActionsButton;
