import React, {useState} from "react";

export default function DishImagePreview({imageSrc, removeDishImg}) {
    const [isRemoveImgAvailable, setRemoveImgState] = useState(false);

    return (
        <div onMouseOver={() => setRemoveImgState(true)}
             onMouseLeave={() => setRemoveImgState(false)}
             className="dish__img__wrapper"
        >
            <div className="dish__img">
                <img src={imageSrc} alt="dish image"/>
            </div>
            {isRemoveImgAvailable &&
            <div className="dish__overlay">
                <span className="dish__img__close-btn" onClick={() => removeDishImg()}>
                    <svg width="23" height="23" viewBox="0 0 23 23" fill="#F5F5F5" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M16.9698 5.65633C16.5793 5.2658 15.9461 5.2658 15.5556 5.65633L11.3131 9.89877L7.07103 5.65666C6.6805 5.26614 6.04734 5.26614 5.65681 5.65666C5.26629 6.04719 5.26629 6.68035 5.65681 7.07088L9.89893 11.313L5.65609 15.5558C5.26557 15.9463 5.26557 16.5795 5.65609 16.97C6.04662 17.3606 6.67978 17.3606 7.07031 16.97L11.3131 12.7272L15.5563 16.9704C15.9468 17.3609 16.58 17.3609 16.9705 16.9704C17.361 16.5798 17.361 15.9467 16.9705 15.5562L12.7274 11.313L16.9698 7.07054C17.3603 6.68001 17.3603 6.04685 16.9698 5.65633Z" fill="#363347"/>
                    </svg>
                </span>
            </div>}
        </div>
    )
}
