import defaultSettings from './config/default.json';
import _ from 'lodash';

const env = process.env.REACT_APP_ENVIRONMENT_NAME || 'local';

const envConfig = require(`./config/env/${env}.json`);

const config = _.merge(defaultSettings, envConfig);

export default config;
