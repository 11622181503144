import React, {useState} from "react";
import './UserOverview.css';
import {get} from "lodash";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

const UserOverview = ({user, logout}) => {
    const [showLogout, setShowLogout] = useState(false);
    return (
        <div className="user-overview-wrapper">
            <Logout logout={logout}/>
            {showLogout &&
            <div className="user-overview">
                <div className="user-overview__avatar"><span>{user.firstName && user.firstName.substring(0, 1)}{user.lastName && user.lastName.substring(0, 1)}</span></div>
                <div className="user-overview__info">
                    <div className="user-overview__name">
                        <span>{user.firstName} {user.lastName}</span>
                    </div>
                    <div className="user-overview__role">Владелец</div>
                </div>
            </div>
            }
        </div>
    );
};

const Logout = ({logout}) => {
    const {t} = useTranslation('common');

    return (
        <div className="user-logout" onClick={() => logout()}>
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 2.35005V11.6499C17.9993 12.2726 17.7516 12.8696 17.3114 13.3099C16.8711 13.7502 16.2742 13.9979 15.6516 13.9986H12.15C12.0018 14.0061 11.8536 13.9834 11.7144 13.9318C11.5752 13.8802 11.448 13.8009 11.3404 13.6986C11.2329 13.5963 11.1472 13.4732 11.0887 13.3368C11.0302 13.2004 11 13.0535 11 12.9051C11 12.7566 11.0302 12.6097 11.0887 12.4733C11.1472 12.3369 11.2329 12.2138 11.3404 12.1116C11.448 12.0093 11.5752 11.9299 11.7144 11.8784C11.8536 11.8268 12.0018 11.8041 12.15 11.8115H15.6516C15.6945 11.8115 15.7356 11.7945 15.7659 11.7642C15.7962 11.7339 15.8132 11.6928 15.8132 11.6499V2.35005C15.8132 2.30719 15.7962 2.26609 15.7659 2.23579C15.7356 2.20548 15.6945 2.18846 15.6516 2.18846H12.15C12.0018 2.19594 11.8536 2.17321 11.7144 2.12163C11.5752 2.07005 11.448 1.99072 11.3404 1.88844C11.2329 1.78616 11.1472 1.66307 11.0887 1.52666C11.0302 1.39025 11 1.24336 11 1.09493C11 0.946489 11.0302 0.799602 11.0887 0.663191C11.1472 0.526781 11.2329 0.403695 11.3404 0.301415C11.448 0.199135 11.5752 0.119796 11.7144 0.0682202C11.8536 0.0166441 12.0018 -0.00609217 12.15 0.0013931H15.6516C16.2742 0.00213043 16.8711 0.249814 17.3114 0.690113C17.7516 1.13041 17.9993 1.72737 18 2.35005Z" fill="#FBFCFF"/>
                <path d="M12 7.4973C12 7.80547 11.8831 8.10106 11.6749 8.31925C11.4668 8.53744 11.1844 8.66041 10.8896 8.66119H3.7883L6.05438 11.0156C6.15809 11.1233 6.24041 11.2513 6.29658 11.3923C6.35276 11.5334 6.38168 11.6847 6.38168 11.8375C6.38168 11.9902 6.35276 12.1415 6.29658 12.2826C6.24041 12.4236 6.15809 12.5517 6.05438 12.6593C5.95148 12.7673 5.8292 12.853 5.69455 12.9114C5.55991 12.9699 5.41555 13 5.26975 13C5.12395 13 4.97959 12.9699 4.84495 12.9114C4.7103 12.853 4.58802 12.7673 4.48512 12.6593L0.318373 8.32061C0.114154 8.09972 0 7.80452 0 7.4973C0 7.19008 0.114154 6.89488 0.318373 6.67399L4.47096 2.33827C4.62662 2.17656 4.82452 2.06655 5.0398 2.02208C5.25508 1.9776 5.47813 2.00064 5.68092 2.0883C5.88371 2.17596 6.0572 2.32432 6.17959 2.51476C6.30198 2.70519 6.3678 2.92919 6.3688 3.15862C6.36897 3.31178 6.34001 3.46344 6.2836 3.60479C6.22719 3.74613 6.14446 3.87433 6.04022 3.98194L3.7883 6.35118H10.8896C11.1815 6.35192 11.4613 6.47245 11.669 6.68681C11.8767 6.90117 11.9955 7.19222 12 7.4973Z" fill="#FBFCFF"/>
            </svg>
            <span>{t('logout')}</span>
        </div>
    );
}
const mapStateToProps = state => {
    return { user: get(state,'user.userData') };
};
export default connect(mapStateToProps)(UserOverview);
