import config from "../../settings";
const axios = require('axios');
const apiUrl = config.services.orryAPI.public;

export async function getCurrentUser () {
    const token = localStorage.getItem('login');
    const userURI = apiUrl + '/wp-json/wp/v2/users/me';

    return await axios({
        method: 'POST',
        url: userURI,
        headers: { 'Authorization': 'Bearer ' + token }
    }).then((response) => {
        if ( response.status === 200 ) {
            return response.data;
        }
    }).catch((error) => {
        throw new Error(error)
    });
}
