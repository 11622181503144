export const RESTAURANT_ADD = 'RESTAURANT_ADD';
export const RESTAURANT_REMOVE_ONE = 'RESTAURANT_REMOVE_ONE';
export const RESTAURANTS_LOAD = 'RESTAURANTS_LOAD';
export const RESTAURANTS_SET_ACTIVE = 'RESTAURANTS_SET_ACTIVE';
export const RESTAURANT_UPDATE = 'RESTAURANT_UPDATE';

export const MENU_LOAD = 'MENU_LOAD';
export const MENU_UPDATE = 'MENU_UPDATE';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const ADD_ACTIVE_CATEGORY_ID = 'ADD_ACTIVE_CATEGORY_ID';
export const SORT_CATEGORIES = 'SORT_CATEGORIES';
export const ADD_DISH = 'ADD_DISH';
export const UPDATE_DISH = 'UPDATE_DISH';
export const DELETE_DISH = 'DELETE_DISH';
export const ADD_MENU_PREVIEW = 'ADD_MENU_PREVIEW';
export const DELETE_MENU_PREVIEW = 'DELETE_MENU_PREVIEW';
export const DELETE_MENU_PREVIEW_URL = 'DELETE_MENU_PREVIEW_URL';
export const MENU_EDITED = 'MENU_EDITED';
export const SET_MENU_DISABLED = 'SET_MENU_DISABLED';

export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const UPDATE_USER = 'UPDATE_USER';

export const RESET_STORE = 'RESET_STORE';

export const SHOW_SNACKBAR_ERROR = 'SHOW_SNACKBAR_ERROR';
export const HIDE_SNACKBAR_ERROR = 'HIDE_SNACKBAR_ERROR';

