import React from "react";
import {useModal} from "../../context/model-context";
import EditDish from "./EditDish";
import Button from "../base-components/Button";
import ActionsButton from "../base-components/ActionsButton/ActionsButton";
import {deleteDish} from "../../store/menu/actions";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

function Dish({id,name,image,price,ingredients,weight}) {
    const { setModal } = useModal();
    const dispatch = useDispatch();
    const {t} = useTranslation('common');
    const dishClasses = `dish ${!!image ? 'dish--with-image' : ''}`;

    return (
        <div className={dishClasses}>
            {
                image && <div className='dish__image'>
                    <img src={image} alt="Dish Image"/>
                </div>
            }
            <div className='dish__info'>
                <h3 className='dish__name'>
                    {name}
                </h3>
                <p className="dish__ingredients">{ingredients}</p>

                <div className="dish__bottom-section">
                    <div className="dish__characteristics">
                        <p>
                            {weight}
                        </p>
                    </div>
                    <div className="dish__price">
                        {price} грн
                    </div>
                </div>
                <div className="dish-edit-action">
                    <ActionsButton fill='#212529'>
                        <Button onClick={() => setModal(
                            <EditDish
                                id={id}
                                dishName={name}
                                dishImg={image}
                                dishPrice={price}
                                dishIngredients={ingredients}
                                dishWeight={weight}
                            />)}
                        >{t('edit')}</Button>
                        <Button onClick={() => dispatch(deleteDish(id))}>{t('delete')}</Button>
                    </ActionsButton>
                </div>
            </div>
        </div>
    )
}
export default Dish;
