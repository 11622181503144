import React from 'react';
import './ValidationHint.css';

const ValidationHint = ({validationError}) => {
    return (
        <div className='validation-hint'>
            {validationError && <React.Fragment><span className='validation-hint__icon'></span><span>{validationError.message}</span></React.Fragment>}
        </div>
    );
};

export default ValidationHint;
