import React from "react";
import {MenuProvider, useMenus} from "../../hooks/menu-hooks.js";
import { ModalProvider } from "../../context/model-context";

import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";

import RestaurantList from "../RestaurantList/RestaurantList";
import AddRestaurant from "../AddRestaurant/AddRestaurant";
import EditRestaurant from "../EditRestaurant/EditRestaurant";
import AddMenu from "../AddMenu/AddMenu";
import SaveMenuForm from "../AddMenu/SaveMenuForm";
import {get} from "lodash";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import SnackbarError from "../SnackbarError/SnackbarError";
import {showSnackbarError} from "../../store/general/actions";

function DashboardContent(props) {
    const {restaurants, imageInvalidMessage, setImageInvalidMessage} = props;
    let { path, url } = useRouteMatch();
    const {t} = useTranslation('common');
    const toolbarClasses = `dashboard__toolbar ${!!imageInvalidMessage ? 'dashboard__toolbar-error' : ''}`;
    const toolbarSettingsClasses = `dashboard__settings ${!!imageInvalidMessage ? 'dashboard__settings--hide' : ''}`;

    return (
        <div className="dashboard__content">
            <ModalProvider>
            <Switch>
                <Route exact path={path}>
                    <h1 className='dashboard__page-name'>{t('restaurant.title')}</h1>
                    <RestaurantList restaurants={restaurants} url={url} path={path}/>
                </Route>
                <Route path={`${path}/restaurant/edit/:restaurantId`}>
                   <EditRestaurant />
                </Route>
                <Route path={`${path}/restaurant/new`}>
                    <AddRestaurant />
                </Route>
                <Route path={`${path}/menu/new`}>
                    <MenuProvider>
                            <div className={toolbarClasses}>
                                <h1 className='dashboard__page-name'>{t('sidebar.menu.menu')}</h1>
                                {!!imageInvalidMessage
                                    && <SnackbarError textKey={imageInvalidMessage} closeSnackbar={setImageInvalidMessage}/>
                                }
                                <div className={toolbarSettingsClasses} >
                                    <SaveMenuForm />
                                </div>
                            </div>
                            {props.restaurantId === undefined}
                            <AddMenu />
                    </MenuProvider>
                </Route>
            </Switch>
            </ModalProvider>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        restaurantId: get(state,'restaurants.activeRestaurant.id'),
        imageInvalidMessage: get(state, 'general.errorTextKey')
    };
};

const mapDispatchToProps = dispatch => ({
    setImageInvalidMessage: invalidMessageKey => {
        dispatch(showSnackbarError(invalidMessageKey))
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(DashboardContent);
