import React from 'react';
import './NavigationDropdown.css';
import {get} from "lodash";
import {connect} from "react-redux";
import {NavLink, useRouteMatch, useLocation} from "react-router-dom";

const NavigationDropdown = (props) => {
    let { url } = useRouteMatch();
    return (
        <NavLink className="navigation-dropdown-link" to={url} activeClassName="navigation-dropdown-link--active" isActive={(match, location) => {
            if(location.pathname === '/dashboard') {
                return true;
            }
            return false;
        }}>
            <div className="navigation-dropdown">
                <div className='navigation-dropdown-icon'></div>
                <div className='navigation-dropdown__active-element'>
                    <span className='navigation-dropdown__active-element-name'>{props.restaurant.title ? props.restaurant.title : 'Мои рестораны'}</span>
                </div>
            </div>
        </NavLink>
    );
};
const mapStateToProps = state => {
    return { restaurant: get(state,'restaurants.activeRestaurant') };
};
export default connect(mapStateToProps)(NavigationDropdown);
