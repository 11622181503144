import { REGISTER_USER_SUCCESS, UPDATE_USER } from '../types';

const defaultState = {
    userData: {},
    registerError: null,
};

export function userReducer(state = defaultState, action) {
    switch (action.type) {
        case REGISTER_USER_SUCCESS:
            {
                const data = action.payload;
                if (data) {
                    return {
                        ...state,
                        userData:  data,
                    }
                }
            }
        case UPDATE_USER:
            {
                console.log(UPDATE_USER, action.payload);
                const data = action.payload;
                if (data) {
                    return {
                        ...state,
                        userData:  data,
                    }
                }
            }
    }

    return state;
}
