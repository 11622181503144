import { useInput } from "../../hooks/hooks";
import Toggle from "../base-components/Toggle";
import React, {useState} from "react";
import {useDispatch} from 'react-redux'
import {addCategory} from "../../store/menu/actions";
import Button from "../base-components/Button";
import ToggleSection from "../base-components/Toggle/ToggleSection";
import HelpTooltip from "../base-components/HelpTooltip";
import InformForm from "../InformForm";
import {useTranslation} from "react-i18next";
export default function AddMenuCategoryForm({numberOfCategories}) {
    const [nameProps, resetName] = useInput("");
    const [homeChecked, setHome] = useState(numberOfCategories === 1);
    const [countOfCategories, setCountOfCategories] = useState(numberOfCategories);
    const [isDishAdded, setDishAdded] = useState(false);
    const {t} = useTranslation('common');
    const dispatch = useDispatch();
    const submit = e => {
        e.preventDefault();
        setDishAdded(true);
        dispatch(addCategory(nameProps.value, homeChecked));
        resetName();
        setHome(false);
    };
    return (
        <>
            {isDishAdded &&
                <InformForm
                    title={t('menu.menu.popup.createCategory.inform.title')}
                    description={t('menu.menu.popup.createCategory.inform.description')}
                    buttonText={t('menu.menu.popup.createCategory.inform.buttonText')}
                    handleOkClick={() => {
                        setDishAdded(false);
                        setCountOfCategories(countOfCategories + 1);
                    }}
                />
            }
            {!isDishAdded &&
                <form className='modal-form' onSubmit={submit}>
                <h2 className="modal-form__title">{t('menu.menu.popup.createCategory.title')} <HelpTooltip content={<div><h4>{t('prompt')}</h4><p>{t('menu.menu.popup.createCategory.tooltip')}</p></div>} /></h2>
                <ToggleSection title={t('menu.menu.popup.createCategory.form.toggle')}>
                    <Toggle checked={homeChecked} onChange={(checked) => setHome(checked)} disabled={countOfCategories === 1} />
                </ToggleSection>
                <div className="modal-form__fieldset">
                    <label htmlFor="restaurant_name">{t('menu.menu.popup.createCategory.form.label')}</label>
                    <input
                        {...nameProps}
                        type="text"
                        placeholder={t('menu.menu.popup.createCategory.form.placeholder')}
                        required
                    />
                </div>
                <Button disabled={!nameProps.value}>{t('menu.menu.popup.createCategory.form.button.text')}</Button>
            </form>
            }
        </>
    );
}
