import { useHistory } from "react-router-dom";
import './GoBack.css';
import {useTranslation} from "react-i18next";

const GoBack = () => {
    const history = useHistory();
    const {t} = useTranslation('common');
    const routeChange = () => {
        history.goBack()
    };
    return (
        <div className='go-back'>
            <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.96857 0.341615C5.15151 0.152953 5.44811 0.152953 5.63104 0.341615C5.81398 0.530277 5.81398 0.836158 5.63104 1.02482L0.993716 5.80725C0.810778 5.99591 0.514178 5.99591 0.33124 5.80725C0.148303 5.61859 0.148303 5.31271 0.33124 5.12405L4.96857 0.341615Z" fill="#737383"/>
                <rect width="0.951547" height="7.61237" rx="0.475773" transform="matrix(-0.69697 0.7171 -0.69697 -0.7171 5.99902 10.3179)" fill="#737383"/>
            </svg>
            <a onClick={routeChange} className='go-back__link'>{t('goBack')}</a>
        </div>
    );
};

export default GoBack;
