import React from "react";
import {useTranslation} from "react-i18next";
import './SnackbarError.css';
import {ERROR_MESSAGES} from "./ValidationMessageConfig";

export default function SnackbarError({closeSnackbar, textKey, closeBtnShape = 'rect'}) {
    const {t} = useTranslation('common');
    const closeBtnClasses = `snackbar__btn__close snackbar__btn__close--${closeBtnShape}`;

    return (
        <div className="snackbar">
            <div className="snackbar__message">
                <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7845 8.5C17.7845 12.8873 13.9714 16.5 9.19597 16.5C4.42059 16.5 0.607422 12.8873 0.607422 8.5C0.607422 4.11273 4.42059 0.5 9.19597 0.5C13.9714 0.5 17.7845 4.11273 17.7845 8.5Z" stroke="white"/>
                    <rect x="8.36914" y="4.63623" width="1.65246" height="4.63636" rx="0.826232" fill="white"/>
                    <path d="M8.36914 11.5911C8.36914 11.1643 8.7151 10.8184 9.14187 10.8184H9.24888C9.67564 10.8184 10.0216 11.1643 10.0216 11.5911C10.0216 12.0179 9.67564 12.3638 9.24888 12.3638H9.14187C8.7151 12.3638 8.36914 12.0179 8.36914 11.5911Z" fill="white"/>
                </svg>
                <p className="snackbar__text">{t(ERROR_MESSAGES[textKey])}</p>
            </div>
            <span className={closeBtnClasses} onClick={() => closeSnackbar()}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1.45508" y="16" width="2.05704" height="20.5704" rx="1.02852" transform="rotate(-135 1.45508 16)" fill="white"/>
                    <rect x="16" y="14.5459" width="2.05704" height="20.5704" rx="1.02852" transform="rotate(135 16 14.5459)" fill="white"/>
                </svg>
            </span>
        </div>
    )
}
