import * as React from "react";
import { useForm } from "react-hook-form";
import classNames from 'classnames';
import ValidationHint from "../ValidationHint/ValidationHint";
import {useTranslation} from "react-i18next";

export default function LoginForm({onSubmit}) {
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    });
    const {t} = useTranslation('common');

    const classesUsername = classNames(
        'form-field',
        {
            'form-field--error': errors.username
        }
    );

    const classesPassword = classNames(
        'form-field',
        {
            'form-field--error': errors.password
        }
    );

    return (
        <form className="login" method="post" onSubmit={handleSubmit(onSubmit)}>
            <div className='form__row'>
                <label htmlFor="username">{t('signin.form.email.label')}</label>
                <input {...register("username", { required: t('error.validation.required') })}
                       type="text"
                       className={classesUsername}
                       label={t('signin.form.email.label')}
                       id='username'
                       name='username'
                       placeholder={t('signin.form.email.placeholder')}
                />
                <ValidationHint validationError={errors.username}/>
            </div>
            <div className='form__row'>
                <label htmlFor="password">{t('signin.form.password.label')}</label>
                <input {...register("password", { required: t('error.validation.required') })}
                       className={classesPassword}
                       label={t('signin.form.password.label')}
                       type="password"
                       id='password'
                       placeholder={t('signin.form.password.placeholder')}
                       name='password'
                />
                <ValidationHint validationError={errors.password}/>
            </div>
            <button>{t('signin.form.button.label')}</button>
        </form>
    );
}
