import {
    MENU_LOAD, MENU_UPDATE, MENU_EDITED,
    ADD_CATEGORY,
    UPDATE_CATEGORY,
    DELETE_CATEGORY,
    ADD_ACTIVE_CATEGORY_ID,
    SORT_CATEGORIES,
    ADD_DISH,
    UPDATE_DISH, DELETE_DISH,
    ADD_MENU_PREVIEW,
    DELETE_MENU_PREVIEW,
    DELETE_MENU_PREVIEW_URL,
    SET_MENU_DISABLED,
} from '../types';

import { v4 } from "uuid";
import {updateObjectInArray} from "../../utils/helpers";
import {removeObjectFromArray} from "../../utils/helpers";

const _ = require('lodash');

export const defaultState = {
    menu: {
        "id": v4(),
        "title": "",
        "preview": "",
        "previewUrl": "",
        "postStatus": "draft",
        "edited": false,
        "disabled": false,
        "categories": [
            {
                "id": "0",
                "button": true
            }
        ],
        "activeCategoryId": ""
    },
};

export function menuReducer(state = defaultState, action) {
    switch (action.type) {
        case MENU_LOAD: {
            const payload = _.isEmpty(action.payload) ? defaultState.menu : action.payload;
            return {
                ...state,
                menu: payload,
            }
        }
        case ADD_CATEGORY: {
            const catId = v4();
            if(action.payload.home && state.menu.categories.length > 1) {
                state.menu.categories.forEach(category => {
                    for (let key in category) {
                        if (key === 'home') {
                            category[key] = false;
                        }
                    }
                });
            }
            const updatedMenu = {
                ...state.menu,
                categories: [...state.menu['categories'], {
                    id: catId,
                    name: action.payload.name,
                    home: action.payload.home,
                    dishes: []
                }],
                activeCategoryId: (state.menu['categories'].length === 1) ? catId : state.menu.activeCategoryId,
                edited: true,
            }
            return {
                ...state,
                menu: updatedMenu,
            }
        }
        case UPDATE_CATEGORY: {
            const {id, home, name} = action.payload;
            let categories = state.menu.categories;

            if(home) {
                categories = categories.map(obj => {
                    return {...obj, home: false};
                });
            }

            let currentDishCategory = categories.filter(cat => cat.id === id);
            let objIndex = 1;

            if (categories.length) {
                objIndex = categories.findIndex(
                    (
                        obj => obj.id == currentDishCategory[0].id
                    ));
            }

            categories[objIndex].home = home;
            categories[objIndex].name = name;

            return {
                ...state,
                menu: {
                    ...state.menu,
                    categories: [...categories]
                }
            }
        }
        case DELETE_CATEGORY: {
            const {id} = action.payload;
            let filteredDishCategory = state.menu.categories.filter(cat => cat.id !== id);
            return {
                ...state,
                menu: {
                    ...state.menu,
                    categories: [...filteredDishCategory]
                }
            }
        }
        case ADD_ACTIVE_CATEGORY_ID: {
            return {
                ...state,
                menu: {
                    ...state.menu,
                    activeCategoryId: action.payload.categoryId
                }
            }
        }
        case SORT_CATEGORIES: {
            return {
                ...state,
                menu: {
                    ...state.menu,
                    categories: action.payload.sortedCategories
                }
            }
        }
        case ADD_DISH: {
            const {name, image, ingredients, weight, price, activeCategoryId} = action.payload;
            let categories = state.menu.categories;
            let activeDishCategory = categories.filter(cat => cat.id === activeCategoryId);
            let objIndex = 1;

            if (categories.length) {
                objIndex = categories.findIndex(
                    (
                        obj => obj.id === activeDishCategory[0].id
                    ));
            }

            const addedDishes = [...activeDishCategory[0].dishes, {
                id: v4(), name, image, ingredients, weight, price
            }];

            categories[objIndex].dishes = addedDishes;

            return {
                ...state,
                menu: {
                    ...state.menu,
                    categories: categories,
                    edited: true,
                }
            }
        }
        case UPDATE_DISH: {
            const {id, name, image, ingredients, weight, price} = action.payload;
            let categories = state.menu.categories;
            let activeDishCategoryToUpdate = categories.filter(cat => cat.id === state.menu.activeCategoryId);
            const objIndex = categories.findIndex((obj => obj.id == activeDishCategoryToUpdate[0].id));
            const dishIndex = activeDishCategoryToUpdate[0].dishes.findIndex((dishObj => dishObj.id == id));
            let updatedDishes = updateObjectInArray(activeDishCategoryToUpdate[0].dishes, dishIndex, {
                id,
                name,
                image,
                ingredients,
                weight,
                price
            });
            categories[objIndex].dishes = updatedDishes;
            return {
                ...state,
                menu: {
                    ...state.menu,
                    categories: categories,
                    edited: true,
                }
            }
        }
        case DELETE_DISH: {
            const {id} = action.payload;
            let categories = state.menu.categories;
            let activeDishCategoryToUpdate = categories.filter(cat => cat.id === state.menu.activeCategoryId);
            const objIndex = categories.findIndex((obj => obj.id == activeDishCategoryToUpdate[0].id));
            const dishIndex = activeDishCategoryToUpdate[0].dishes.findIndex((dishObj => dishObj.id == id));
            let updatedDishes = removeObjectFromArray(activeDishCategoryToUpdate[0].dishes, dishIndex);
            categories[objIndex].dishes = updatedDishes;
            return {
                ...state,
                menu: {
                    ...state.menu,
                    categories: categories,
                    edited: true,
                }
            }
        }
        case ADD_MENU_PREVIEW: {
            const {preview} = action.payload;
            return {
                ...state,
                menu: {
                    ...state.menu,
                    preview: preview,
                    edited: true,
                }
            }
        }
        case SET_MENU_DISABLED: {
            return {
                ...state,
                menu: {
                    ...state.menu,
                    disabled: action.payload.disabled,
                }
            }
        }
        case DELETE_MENU_PREVIEW: {
            return {
                ...state,
                menu: {
                    ...state.menu,
                    preview: '',
                    edited: true,
                }
            }
        }
        case DELETE_MENU_PREVIEW_URL: {
            return {
                ...state,
                menu: {
                    ...state.menu,
                    previewUrl: '',
                    edited: true,
                }
            }
        }
        case MENU_EDITED: {
            return {
                ...state,
                menu: {
                    ...state.menu,
                    edited: true,
                }
            }
        }
        case MENU_UPDATE: {
            const { id, wpid, postStatus, previewUrl, } = action.payload;
            return {
                ...state,
                menu: {...state.menu, id, wpid, postStatus, previewUrl}
            }
        }
        default: {
            return {...state}
        }
    }

    return state;
}
