import config from "../../settings";
const axios = require('axios');
const apiUrl = config.services.orryAPI.public;
export async function loginUser(loginData) {
    const res = await axios.post( `${apiUrl}/wp-json/jwt-auth/v1/token/`,
        {
            username: loginData.username,
            password: loginData.password
        })
        .then(function (response) {
            if ( response.status === 200 ) {
                const data = response.data;
                localStorage.setItem( 'login', data.token );
                return {'status': 200}
            }

        })
        .catch(function (error) {
            const errorText = (error.response) ? error.response.data.message : error.message;
            const errorStatus = (error.response) ? error.response.status : '400';

            return {
                'status': errorStatus,
                'error': errorText
            }
        });
    return res;
}
