import React, {useState} from "react";
import ConfirmationForm from "../ConfirmationForm";
import {useModal} from "../../context/model-context";
import {updateDish, deleteDish} from "../../store/menu/actions";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import DishForm from "./DishForm";

export default function EditDish({id, dishImg, dishName, dishIngredients, dishWeight, dishPrice}) {
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const {t} = useTranslation('common');
    const dispatch = useDispatch();
    const { setModal } = useModal();
    const submitForm = formValues => {
        dispatch(updateDish({id, ...formValues}));
        setModal();
    };

    return (
        <>
            {deleteConfirm ?
                <ConfirmationForm
                    title={t("menu.menu.popup.editDish.inform.title")}
                    handleCancelClick={() => setDeleteConfirm(false)}
                    handleOkClick={() => {
                        dispatch(deleteDish(id));
                        setModal();
                    }}/>
                :<DishForm
                    formType="editDish"
                    formValue={{
                        image: dishImg,
                        name: dishName,
                        weight: dishWeight,
                        price: dishPrice,
                        ingredients: dishIngredients
                    }}
                    submitForm={submitForm}
                    deleteDish={() => setDeleteConfirm(true)}
                />
            }
        </>
    );
}
