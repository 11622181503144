import React, {useState} from "react";
import {addDish} from "../../store/menu/actions";
import {useDispatch} from "react-redux";
import InformForm from "../InformForm";
import {useTranslation} from "react-i18next";
import DishForm from "./DishForm";

export default function AddDish({activeCategoryId}) {
    const dispatch = useDispatch();
    const {t} = useTranslation('common');
    const [isDishAdded, setDishAdded] = useState(false);

    const submitForm = formValues => {
        setDishAdded(true);
        dispatch(addDish({activeCategoryId, ...formValues}));
    };

    return (
        <>
            {isDishAdded ?
                <InformForm
                    title={t("menu.menu.popup.createDish.inform.title")}
                    description={t("menu.menu.popup.createDish.inform.description")}
                    buttonText={t("menu.menu.popup.createDish.inform.buttonText")}
                    handleOkClick={() => {
                        setDishAdded(false)
                    }}
                />
                :<DishForm formType="createDish" submitForm={submitForm}/>
            }
        </>
    );
}
