import React, {useEffect, useState} from "react";
import './Toggle.css';
import classNames from "classnames";

const Toggle = ({checked, className, onChange = f => f, disabled}) => {
    const [checkedState, setChecked] = useState(false);
    useEffect(() => {
        setChecked(checked);
    }, [checked]);

    const classesToggle = classNames(
        'form-toggle',
        className,
        {
            'is-checked': checkedState
        }
    );
    return (
        <div className={classesToggle} onClick={() => {
            setChecked(!checkedState);
            onChange(!checkedState);
        }}>
            <input className="form-toggle__input" type="checkbox" disabled={disabled}/>
            <span className="form-toggle__track"></span>
            <span className="form-toggle__thumb"></span>
        </div>
    );
};

export default Toggle;
