import {saveMenu} from "../../services/menusService/menu.service";
import Button from "../base-components/Button";
import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {updateMenu} from "../../store/menu/actions";
import {get} from "lodash";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

function SaveMenuForm({restaurantId, menu}) {
    const {t} = useTranslation('common');
    const updateButtonLabel = t('update');
    const publishButtonLabel = t('publish');
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [buttonLabel, setButtonLabel] = useState(t('loading'));

    useEffect(() => {
        const initialButtonLabel = (menu.postStatus === 'publish') ? updateButtonLabel : publishButtonLabel;
        setButtonLabel(initialButtonLabel);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [menu]);

    const handleButtonClicked = async () => {
        setLoading(true);

        const response = await saveMenu({
            menu_name: "test",
            restaurant_id: restaurantId,
            menu: menu
        });

        if (response.status == 'success') {
            dispatch(updateMenu(response.data));
            setSuccess(true);
            setLoading(false);
            setTimeout(() => {
                setSuccess(false);
                setButtonLabel(updateButtonLabel);
            }, 1000);
        }
    }
    return (
        <>
            <Button
                className='publish-button'
                loading={loading}
                success={success}
                small
                rounded
                onClick={
                    () => { handleButtonClicked() }
                }
            >
                {buttonLabel}
            </Button>
        </>
    )
}
const mapStateToProps = state => {
    return {
        restaurantId: get(state,'restaurants.activeRestaurant.id'),
        menu: get(state,'menu.menu'),
    };
};
export default connect(mapStateToProps)(SaveMenuForm);
