import config from "../../settings";
const axios = require('axios');
const apiUrl = config.services.orryAPI.public;
export async function registerUser(registerData) {
    const res = await axios.post( `${apiUrl}/wp-json/wp/v2/users/register`,
        {
            username: registerData.username,
            password: registerData.password,
            email: registerData.email,
        })
        .then(function (response) {
            if ( response.status === 200 ) {
                const data = response.data;
                return {
                    'status': 200,
                    'data': data
                }
            }

        })
        .catch(function (error) {
            const errorText = (error.response) ? error.response.data.message : error.message;
            const errorStatus = (error.response) ? error.response.status : '400';
            return {
                'status': errorStatus,
                'error': errorText
            }
        });
    return res;
}
