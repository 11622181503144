import React from "react";
import Button from "../base-components/Button";
import './ConfirmationForm.css';
import {useTranslation} from "react-i18next";

function ConfirmationForm({title, handleOkClick, handleCancelClick}) {
    const {t} = useTranslation('common');
    return (
       <div className='confirmation-dialog'>
           <h2 className='confirmation-dialog__title'>{title}</h2>
           <div className="confirmation-dialog__buttons">
               <Button className='confirmation-dialog__button' transparent accent small onClick={() => handleOkClick() }>{t('delete')}</Button>
               <Button className='confirmation-dialog__button' accent small onClick={() => handleCancelClick()}>{t('cancel')}</Button>
           </div>
       </div>
    )
}

export default ConfirmationForm;
