import HelpTooltip from "../base-components/HelpTooltip";
import DishImagePreview from "../ImageDropZone/DishImagePreview";
import ImageDropZoneInput from "../ImageDropZone/ImageDropZoneInput";
import Button from "../base-components/Button";
import React, {useEffect, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";
import SnackbarError from "../SnackbarError/SnackbarError";
import ConfirmationForm from "../ConfirmationForm";
import './DishForm.css';
import '../ImageDropZone/ImageDropZone.css'


export default function DishForm({formType, formValue = {}, submitForm, deleteDish }) {
    const [isDeleteImg, setDeleteImgState] = useState(false);
    const [submitBtnDisabled, disableSubmitBtn] = useState(false);
    const [imageInvalidMessage, setImageInvalidMessage] = useState(null);
    const {t} = useTranslation('common');

    const {
        register,
        handleSubmit,
        getValues,
        control,
        reset,
        setValue,
        watch,
        formState: { isValid, dirtyFields }
    } = useForm({ mode: "onChange", defaultValues: formValue});
    const formValuesChange = watch();
    const imageControlValueChange = watch("image");
    const imageControlValue = getValues("image");
    const imageControlDefaultValue = formValue?.image;

    const onSubmit = (formValues, e) => {
        e.preventDefault();
        submitForm({
            ...formValues,
            image: imageToSave(imageControlValue)
        })
        reset();
    }

    const imagePreview = (imageObj) => {
        return imageObj !== null && typeof imageObj === 'object' ? imageObj?.preview : imageObj;
    }

    const imageToSave = (imageObj) => {
        return imageObj !== null && typeof imageObj === 'object' ? imageObj.base64 : imageObj;
    }

    useEffect(() => {
        const imageNotChanged = (imageControlDefaultValue === imageControlValue || (!imageControlDefaultValue && !imageControlValue));
        const isFormDirty = !!Object.keys(dirtyFields).filter((dirtyField) => dirtyField !== "image").length;
        const isSubmitBtnDisabled = !isValid || (!isFormDirty && imageNotChanged);
        disableSubmitBtn(isSubmitBtnDisabled);
    }, [formValuesChange, isValid, dirtyFields, imageControlValue, imageControlDefaultValue])

    useEffect(() => {
        setImageInvalidMessage(undefined)
    }, [imageControlValueChange]);

    return (
        <>
            {isDeleteImg &&
            <ConfirmationForm
                title={t("menu.menu.popup.dishForm.image.deleteImageConfirmation")}
                handleCancelClick={() => setDeleteImgState(false)}
                handleOkClick={() => {
                    setValue("image", null);
                    setDeleteImgState(false)
                }}/>
            }
            {!!imageInvalidMessage &&
            <SnackbarError textKey={imageInvalidMessage} closeBtnShape='square' closeSnackbar={() => setImageInvalidMessage(null)}/>
            }
            {!isDeleteImg &&
            <form className='dish-form' onSubmit={handleSubmit(onSubmit)}>
                <h2 className="dish-form__title">{t(`menu.menu.popup.${formType}.title`)}
                    <HelpTooltip content={<div><h4>{t('prompt')}</h4><p>{t(`menu.menu.popup.${formType}.tooltip`)}</p></div>}/>
                </h2>
                <div className="dish-form__fieldset">
                    <Controller
                        control={control}
                        name="image"
                        render={(
                            { field: { value, onChange }
                            }) => (
                            <>
                                { !!value ? <DishImagePreview
                                        imageSrc={imagePreview(value)}
                                        removeDishImg={() => setDeleteImgState(true)}
                                    />
                                    : <ImageDropZoneInput chooseImg={onChange} setInvalidImgMessage={setImageInvalidMessage} invalidInput={!!imageInvalidMessage}/>
                                }
                            </>
                        )}
                    />
                </div>
                <div className="dish-form__fieldset">
                    <label htmlFor="restaurant_name">{t("menu.menu.popup.dishForm.name.label")}</label>
                    <input
                        {...register("name", {required: true})}
                        name="name"
                        type="text"
                        placeholder={t("menu.menu.popup.dishForm.name.placeholder")}
                    />
                </div>
                <div className="dish-form__fieldset">
                    <label htmlFor="ingredients">{t("menu.menu.popup.dishForm.ingredients.label")}</label>
                    <textarea
                        {...register("ingredients", {required: true})}
                        name="ingredients"
                        placeholder={t("menu.menu.popup.dishForm.ingredients.placeholder")}
                    />
                </div>
                <div className="dish-form__fieldset">
                    <label htmlFor="weight">{t("menu.menu.popup.dishForm.weight.label")}</label>
                    <input
                        {...register("weight", {required: true})}
                        type="text"
                        name="weight"
                        placeholder={t("menu.menu.popup.dishForm.weight.placeholder")}
                    />
                </div>
                <div className="dish-form__fieldset">
                    <label htmlFor="price">{t("menu.menu.popup.dishForm.price.label")}</label>
                    <input
                        {...register("price", {required: true})}
                        type="text"
                        name="price"
                        placeholder={t("menu.menu.popup.dishForm.price.placeholder")}
                    />
                </div>
                <div className="dish-form__actions">
                    <Button disabled={submitBtnDisabled}>{t(`menu.menu.popup.${formType}.button.text`)}</Button>
                    {formType === "editDish" &&
                    <Button
                        accent
                        onClick={() => deleteDish()}
                    >{t('delete')}</Button>
                    }
                </div>
            </form>
            }
        </>
    )
}
