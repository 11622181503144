import React from "react";
import Dish from "../Dish";

const getDishes = (categories, activeCategoryId) => {
    let activeCategory = categories.filter(cat => cat.id === activeCategoryId);

    if (activeCategory.length) {
        return activeCategory[0].dishes;
    }

    return [];
};

function DishList({categories, activeCategoryId}) {
    const dishesList = getDishes(categories, activeCategoryId);

    return (
        <>
            {!!dishesList.length && <div className='dishes'>
                {dishesList.map((dish) => (
                    <Dish
                        key={dish.id}
                        id={dish.id}
                        name={dish.name}
                        image={dish.image}
                        ingredients={dish.ingredients}
                        weight={dish.weight}
                        price={dish.price}
                    />
                ))}
            </div>}
        </>
    );
}

export default DishList;
