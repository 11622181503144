import React from "react";
import Button from "../base-components/Button";
import './InformForm.css';

function InformForm({title, handleOkClick, description, buttonText}) {
    return (
        <div className='confirmation-dialog'>
            <div className='confirmation-dialog__icon'>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 0C19.2533 0 14.6131 1.40758 10.6663 4.04473C6.71954 6.68188 3.6434 10.4302 1.8269 14.8156C0.0103991 19.201 -0.464881 24.0266 0.461164 28.6822C1.38721 33.3377 3.67299 37.6141 7.02945 40.9706C10.3859 44.327 14.6623 46.6128 19.3178 47.5388C23.9734 48.4649 28.799 47.9896 33.1844 46.1731C37.5698 44.3566 41.3181 41.2805 43.9553 37.3337C46.5924 33.3869 48 28.7467 48 24C47.9923 17.6372 45.4612 11.5372 40.962 7.03798C36.4628 2.53879 30.3628 0.00774001 24 0ZM36.336 17.184L20.352 33.152C20.1234 33.3953 19.8186 33.5534 19.488 33.6C19.3878 33.6194 19.2861 33.6301 19.184 33.632C19.0974 33.6371 19.0106 33.6263 18.928 33.6C18.5974 33.5533 18.2926 33.3953 18.064 33.152L11.664 26.768C11.5157 26.62 11.398 26.4442 11.3177 26.2506C11.2374 26.057 11.196 25.8495 11.196 25.64C11.196 25.4304 11.2374 25.2229 11.3177 25.0294C11.398 24.8358 11.5157 24.66 11.664 24.512C11.9661 24.2122 12.3744 24.044 12.8 24.044C13.2256 24.044 13.6339 24.2122 13.936 24.512L19.216 29.792L34.064 14.928C34.3661 14.6283 34.7744 14.4601 35.2 14.4601C35.6256 14.4601 36.0339 14.6283 36.336 14.928C36.4843 15.076 36.602 15.2518 36.6823 15.4454C36.7626 15.639 36.8039 15.8464 36.8039 16.056C36.8039 16.2655 36.7626 16.473 36.6823 16.6666C36.602 16.8602 36.4843 17.036 36.336 17.184Z" fill="#6AC87C"/>
                </svg>
            </div>
            <h2 className='confirmation-dialog__title'>{title}</h2>
            <div className='confirmation-dialog__description'>
                <p>{description}</p>
            </div>
            <div className="confirmation-dialog__buttons">
                <Button small onClick={() => handleOkClick() }>{buttonText}</Button>
            </div>
        </div>
    )
}

export default InformForm;
