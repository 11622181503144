import React from 'react';
import './ErrorInfoBox.css';

const ErrorInfoBox = (props) => {
    const {text} = props;
    return (
        <div className='error-info-box'>
            <span className='error-info-box__icon'></span>{text}<span></span>
        </div>
    );
};

export default ErrorInfoBox;
