import {HIDE_SNACKBAR_ERROR, SHOW_SNACKBAR_ERROR} from "../types";

const defaultState = {
    errorTextKey: null
}

export function generalReducer(state = defaultState, action) {
    switch (action.type) {
        case SHOW_SNACKBAR_ERROR:
            return {
                ...state,
                errorTextKey: action.payload.errorTextKey
            }
        case HIDE_SNACKBAR_ERROR:
            return {
                ...state,
                errorTextKey: null
            }
    }

    return state;
}
