export const validateImageFile = (file, setInvalidImgMessage) => {
    const TEN_MB = 10485760;
    const IMAGE_TYPE = 'image/';

    if(!file.type.includes(IMAGE_TYPE)) {
        setInvalidImgMessage("WRONG_TYPE");
        return;
    }

    if(file.size > TEN_MB) {
        setInvalidImgMessage("WRONG_SIZE");
        return;
    }

    return null;
}

export const imageResolutionValidation = (uploadedFile, saveImage, setInvalidImgMessage, imageObj) => {
    const objectUrl = URL.createObjectURL(uploadedFile);
    const image = new Image();

    image.onload = function () {
        const {height, width} = image ?? {};
        const ALLOWED_HEIGHT = 768;
        const ALLOWED_WIDTH = 1024;
        if (height > ALLOWED_HEIGHT || width > ALLOWED_WIDTH) {
            setInvalidImgMessage("WRONG_RESOLUTION")
            URL.revokeObjectURL(objectUrl);
        } else {
            saveImage({...imageObj});
        }

    };
    image.src = objectUrl;
}
