import React, {useEffect} from "react";
import './MobileView.css';
import logo from './../../assets/mobile-view-icon.png';

const MobileView = () => {
    useEffect(()  => {
        document.body.classList.add('mobile-view--remove-scroll');
        return () => {
            document.body.classList.remove('mobile-view--remove-scroll');
        };
    });
    return (
        <div className="mobile-view">
            <div className="mobile-view__icon">
                <img src={logo} alt="Mobile view icon" width='100px' height='100px' />
            </div>
            <h1 className='mobile-view__title'>Ууупсс...</h1>
            <div className='mobile-view__description'>
                <p>Извините, наш сервис не предназначен для мобильных устройств, пожалуйста войдите с компьютера</p>
            </div>
        </div>
    );
};

export default MobileView;
