import Button from "../base-components/Button";
import React, {useState} from "react";
import './QRCode.css';
import QRCode from "qrcode";
import {useTranslation} from "react-i18next";
const QrCode = ({menuUrl}) => {
    const [qrImageUrl, setQrImageUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const {t} = useTranslation('common');
    const generateQrCode = async () => {
        try {
            setLoading(true);
            let opts = {
                errorCorrectionLevel: 'H',
                type: 'image/png',
                quality: 1,
                margin: 1,
            }
            const response = await QRCode.toDataURL(menuUrl, opts);
            setQrImageUrl(response);
            setLoading(false);
        } catch (error) {
            setError(true);
            console.log(error);
        }
    }
    return (
        <>
            <h2 className='dashboard-section__title dashboard-section__title-sidebar'>{t('qr.title')}
                {!qrImageUrl &&
                <svg className="qr-code-title-icon" width="26" height="26" viewBox="0 0 26 26" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16.4767 6.44961H13.3022V9.67442H25.9999V0H16.4767V6.44961ZM19.6511 3.22481H22.8255V6.44961H19.6511V3.22481Z"
                        fill="#363347"/>
                    <path
                        d="M13.3023 3.17442V0H0V9.52326H3.32558V12.6977H6.65116V9.52326H9.97674V3.17442H13.3023ZM6.65116 6.34884H3.32558V3.17442H6.65116V6.34884Z"
                        fill="#363347"/>
                    <path d="M19.3488 12.6977H13.3022V16.3256H19.3488V12.6977Z" fill="#363347"/>
                    <path d="M26.0003 12.6977H22.9771V16.3256H26.0003V12.6977Z" fill="#363347"/>
                    <path d="M22.977 16.3256H19.3491V19.3488H22.977V16.3256Z" fill="#363347"/>
                    <path d="M26.0003 19.3488H22.9771V26H26.0003V19.3488Z" fill="#363347"/>
                    <path d="M19.3489 22.9767H16.3257V26H19.3489V22.9767Z" fill="#363347"/>
                    <path
                        d="M9.79535 22.7752H16.3256V19.5504H9.79535V16.3256H0V26H9.79535V22.7752ZM6.53023 22.7752H3.26512V19.5504H6.53023V22.7752Z"
                        fill="#363347"/>
                    <path d="M13.3022 9.67441H9.67432V12.6977H13.3022V9.67441Z" fill="#363347"/>
                </svg>
                }
            </h2>
            <div className='dashboard-section__text'>
                {loading && <span>Генерация кода</span>}
                {qrImageUrl &&
                    <>
                        <p>{t('qr.text')}</p>
                        <div className="qr-code-container">
                            <img src={qrImageUrl} className="qr-code"/>
                            <div className="qr-code-buttons">
                                <a href={qrImageUrl} download='menu-qr' className='button btn-sm'>{t("qr.button.downloadText")}</a>
                            </div>
                        </div>
                    </>
                }
                {!qrImageUrl &&
                    <>
                        <p>{t('qr.text')}</p>
                        <Button transparent onClick={()=>{generateQrCode()}}>{t("qr.button.createText")}</Button>
                    </>
                }
            </div>

        </>
    );
};

export default QrCode;
