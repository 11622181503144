export function updateObjectInArray(array, arrayIndex, newItem) {
    return array.map((item, index) => {
        if (index !== arrayIndex) {
            // This isn't the item we care about - keep it as-is
            return item
        }
        // Otherwise, this is the one we want - return an updated value
        return {
            ...item,
            ...newItem
        }
    })
}
export function removeObjectFromArray(array, arrayIndex) {
    return array.filter(function(value, index, arr){
        return index !== arrayIndex;
    });
}
