import React from "react";
import './Separator.css';
import classNames from "classnames";

const Separator = (props) => {
    const classesSeparator = classNames(
        'separator',
        {
            'separator--big': props.big
        }
    );
    return (
        <div className={classesSeparator}></div>
    );
};

export default Separator;
