import React from "react";
import './Button.css';
import classNames from "classnames";

const Button = (props) => {
    const {children, className, disabled, onClick = f => f } = props;
    const classesButton = classNames(
        className,
        {
            'btn-sm': props.small,
            'btn-icon': props.icon,
            'btn-rounded': props.rounded,
            'btn-transparent': props.transparent,
            'is-accent': props.accent,
            'is-loading': props.loading
        }
    );
    let buttonContent;
    if (props.loading) {
        buttonContent = <LoadingIcon />;
    } else if(props.success) {
        buttonContent = <SuccessIcon />;
    } else {
        buttonContent = children;
    }

    return (
        <button
            className={classesButton}
            disabled={disabled || props.loading}
            onClick={() => {onClick()}}
        >
            {buttonContent}
        </button>
    );
};

const LoadingIcon = () => {
    return (
        <span className="loading-icon">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.9162 10C19.5148 10 20.0061 9.51334 19.9413 8.91829C19.7531 7.18869 19.116 5.53133 18.0847 4.11465C16.84 2.40477 15.0852 1.13355 13.0725 0.483724C11.0599 -0.166105 8.89302 -0.161085 6.8834 0.498063C4.87378 1.15721 3.12489 2.43654 1.8881 4.15218C0.651306 5.86781 -0.0096928 7.9314 0.000107358 10.0463C0.00990752 12.1613 0.690002 14.2186 1.94264 15.9227C3.19528 17.6268 4.95595 18.8899 6.97159 19.5304C8.6416 20.0611 10.4154 20.1417 12.1158 19.7736C12.7008 19.647 13.0072 19.027 12.8181 18.4592C12.6289 17.8913 12.0154 17.5923 11.4269 17.7014C10.1658 17.935 8.86083 17.8564 7.62802 17.4646C6.04929 16.963 4.67025 15.9737 3.68914 14.6389C2.70802 13.3042 2.17534 11.6928 2.16766 10.0363C2.15999 8.37979 2.67771 6.7635 3.64642 5.41974C4.61512 4.07598 5.98493 3.07396 7.55895 2.55768C9.13297 2.04141 10.8301 2.03748 12.4065 2.54645C13.9829 3.05543 15.3574 4.0511 16.3323 5.39035C17.0936 6.43614 17.5807 7.64939 17.7576 8.91965C17.8401 9.51249 18.3176 10 18.9162 10Z" fill="white"/>
            </svg>
        </span>
    )
};
const SuccessIcon = () => {
    return (
        <span>
           <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
               <rect y="8.55881" width="2" height="10" rx="1" transform="rotate(-45 0 8.55881)" fill="white"/>
               <rect x="6.99805" y="15.5564" width="2" height="20" rx="1" transform="rotate(-135 6.99805 15.5564)" fill="white"/>
           </svg>
        </span>
    )
};

export default Button;
