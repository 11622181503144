import {useParams, withRouter} from "react-router-dom";
import GoBack from "../GoBack/GoBack";
import React, {useState} from "react";
import {get} from "lodash";
import {connect, useDispatch} from "react-redux";
import config from "../../settings";
import axios from "axios";
import EditRestaurantForm from "./EditRestaurentFrom";
import {updateRestaurant} from '../../store/restaurants/actions';
import InformForm from "../InformForm";
import {useModal} from "../../context/model-context";
import {useHistory} from "react-router-dom";
const apiUrl = config.services.orryAPI.public;
function EditRestaurant({restaurants}) {
    const { restaurantId } = useParams();
    const restaurant = restaurants.filter( item => item.id === parseInt(restaurantId, 10))[0];
    const [restaurantName, setRestaurantName] = useState(restaurant.title);
    const { setModal } = useModal();
    const dispatch = useDispatch();
    const history = useHistory();
    const [error, setError] = useState(false);
    const handleRestaurantName = (event) => {
        setRestaurantName(event.target.value);
    }
    const handleSubmit = async (data) => {
        let _this = this;
        const token = localStorage.getItem('login');
        const userURI = `${apiUrl}/wp-json/wp/v1/restaurants`;
        axios({
            method: 'PUT',
            url: userURI,
            headers: { 'Authorization': 'Bearer ' + token },
            data: {
                id: restaurantId,
                restaurant_name: restaurantName
            }
        }).then(function (response) {
            if ( response.status === 200 ) {
                const data = response.data;
                dispatch(updateRestaurant(data));
                setModal(<InformForm
                    title='Ресторан был успешно обновлен'
                    description=''
                    buttonText='К списку ресторанов'
                    handleOkClick={() => {
                        setModal();
                        history.push("/dashboard");
                    }}
                />);
            }
        })
            .catch(function (error) {
                const errorText = (error.response) ? error.response.data.message : error.message;
                setError(errorText);
            });
    }
    return (
        <div>
            <GoBack />
            <h1 className='dashboard__page-name'>{restaurant.title}</h1>
            <EditRestaurantForm onSubmit={handleSubmit} handleRestaurantName={handleRestaurantName} restaurantName={restaurant.title}/>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        restaurants: get(state,'restaurants.restaurants'),
    };
};
export default connect(mapStateToProps )(EditRestaurant);
