import {
    MENU_LOAD,
    MENU_UPDATE,
    ADD_CATEGORY,
    UPDATE_CATEGORY,
    DELETE_CATEGORY,
    ADD_ACTIVE_CATEGORY_ID,
    SORT_CATEGORIES,
    ADD_DISH,
    UPDATE_DISH,
    DELETE_DISH,
    ADD_MENU_PREVIEW,
    SET_MENU_DISABLED,
    DELETE_MENU_PREVIEW_URL
} from "../types";

export const loadMenu = menu => dispatch => {
    dispatch({
        type: MENU_LOAD,
        payload: menu,
    });
};
export const updateMenu = menu => dispatch => {
    dispatch({
        type: MENU_UPDATE,
        payload: menu,
    });
};
export const addCategory = (name, home) => dispatch => {
    dispatch({
        type: ADD_CATEGORY,
        payload: {name, home},
    });
};
export const updateCategory = (id, name, home) => dispatch => {
    dispatch({
        type: UPDATE_CATEGORY,
        payload: {id, name, home},
    });
};
export const deleteCategory = (id) => dispatch => {
    dispatch({
        type: DELETE_CATEGORY,
        payload: {id},
    });
};
export const setActiveCategoryId = (categoryId) => dispatch => {
    dispatch({
        type: ADD_ACTIVE_CATEGORY_ID,
        payload: {categoryId},
    });
}
export const sortCategories = (sortedCategories) => dispatch => {
    dispatch({
        type: SORT_CATEGORIES,
        payload: {sortedCategories},
    });
}
export const addDish = (dish) => dispatch => {
    dispatch({
        type: ADD_DISH,
        payload: dish,
    });
}

export const updateDish = (dish) => dispatch => {
    dispatch({
        type: UPDATE_DISH,
        payload: dish,
    });
}

export const deleteDish = (id) => dispatch => {
    dispatch({
        type: DELETE_DISH,
        payload: {id},
    });
}

export const addPreview = (preview) => dispatch => {
    dispatch({
        type: ADD_MENU_PREVIEW,
        payload: {preview},
    });
}
export const deleteLogoPreview = () => dispatch => {
    dispatch({
        type: DELETE_MENU_PREVIEW_URL
    });
}
export const setMenuDisabled = (disabled) => dispatch => {
    dispatch({
        type: SET_MENU_DISABLED,
        payload: {disabled},
    });
}


