import React from "react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import ValidationHint from "../ValidationHint/ValidationHint";
import {useTranslation} from "react-i18next";

function AddRestaurantForm({onSubmit, handleRestaurantName}) {
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: "onBlur" // "onChange"
    });
    const {t} = useTranslation('common');
    const classesRestaurantName = classNames(
        'form-field',
        {
            'form-field--error': errors.restaurant_name
        }
    );
    return (
            <form className='add-restaurant' method="post" onSubmit={handleSubmit(onSubmit)}>
                <section className='dashboard-section'>
                    <div className="form__row">
                        <label htmlFor="restaurant_name">{t('restaurant.form.label')}</label>
                        <input {...register("restaurant_name", { required: t('error.validation.required') })}
                               type="text"
                               name="restaurant_name"
                               className={classesRestaurantName}
                               label="Название"
                               id="restaurant_name"
                               placeholder={t('restaurant.form.placeholder')}
                               onChange={ (value) => handleRestaurantName( value ) }
                        />
                        <ValidationHint validationError={errors.restaurant_name}/>
                    </div>
                </section>
                <section className='dashboard-section'>
                    <h2 className='dashboard-section__title'>{t('restaurant.typeOfPlan')}</h2>
                    <p>{t('restaurant.freePlan')}</p>
                </section>
                <button className='btn-sm btn-a-right'>{t('create')}</button>
            </form>
    );
}

export default AddRestaurantForm;
