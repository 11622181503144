import config from "../../settings";
const axios = require('axios');
const apiUrl = config.services.orryAPI.public;

export async function getRestaurants() {
    const token = localStorage.getItem('login');
    const res = axios({
        method: 'GET',
        url: `${apiUrl}/wp-json/wp/v1/restaurants`,
        headers: { 'Authorization': 'Bearer ' + token }
    }).then((response) => {
        if ( response.status === 200 ) {
            return response.data;
        }
    }).catch((error) => {
        throw new Error(error)
    });
    return res;
}

export async function createRestaurant(restaurantName) {
    const token = localStorage.getItem('login');
    const userURI = `${apiUrl}/wp-json/wp/v1/restaurants`;
    return axios({
        method: 'POST',
        url: userURI,
        headers: {'Authorization': 'Bearer ' + token},
        data: {
            restaurant_name: restaurantName
        }
    }).then(({status, data}) => {
        if (status === 200) {
            return data;
        }
    }).catch((error) => {
        throw new Error(error)
    })
}

export async function deleteRestaurant(restaurantId) {
    const token = localStorage.getItem('login');
    const restURI = `${apiUrl}/wp-json/wp/v1/restaurants`;
    const method = 'DELETE';
    const res = await axios({
        method: method,
        url: restURI,
        headers: { 'Authorization': 'Bearer ' + token },
        data: {id: restaurantId}
    }).then(function (response) {
        if ( response.status === 200 ) {
            return response.data;
        }
    }).catch(function (error) {
        console.log('???????? catch ', error);
    });
    return res;
}
