import React, {useState, useEffect} from "react";
import './AddMenu.css';
import Button from "../base-components/Button";
import Toggle from "../base-components/Toggle"
import HelpTooltip from "../base-components/HelpTooltip";
import DishCategories from "../DishCategories";
import DishList from "../DishList";
import AddDish from "../Dish/AddDish";
import {useModal} from "../../context/model-context";
import QrCode from "../QRCode";
import {getMenu} from "../../services/menusService/menu.service";
import {connect} from "react-redux";
import {get} from "lodash";
import config from "../../settings";
import {addPreview, deleteLogoPreview, loadMenu, setMenuDisabled} from "../../store/menu/actions";
import {useDispatch} from "react-redux";
import LogoPreview from "../ImageDropZone/LogoPreview";
import {useTranslation} from "react-i18next";
import ImageDropZoneInput from "../ImageDropZone/ImageDropZoneInput";
import {showSnackbarError, hideSnackbarError} from "../../store/general/actions";
import {IMAGE_UPLOAD_ERROR_MESSAGE_KEYS} from "../SnackbarError/ValidationMessageConfig";

const getDishes = (menu, activeCategoryId) => {
    if (!menu) {
        return [];
    }
    let activeCategory = menu.categories.filter(cat => cat.id === activeCategoryId);

    if (activeCategory.length) {
        return activeCategory[0].dishes;
    }

    return [];
};

function AddMenu({restaurantId, menu, isImageInvalid}) {
    const {categories, activeCategoryId, previewUrl, parent_restaurant_id, disabled} = menu;

    const { setModal } = useModal();
    const [loading, setLoading] = useState(false);
    const [logoPreview, setLogoPreview] = useState(previewUrl);
    const dispatch = useDispatch();
    const {t} = useTranslation('common');
    const menuOrryUrl = `${config.services.orryMenu.public}/${restaurantId}/${menu.id}`;

    useEffect(() => {
        let mounted = true;
        const getMenusData = async () => {
            setLoading(true)
            const menuData = await getMenu(restaurantId);
            dispatch(loadMenu(menuData.data.length ? menuData.data[0] : {}));
            if(mounted && menuData.length) {
                console.log('????', mounted && menuData.data.length);
            }
            setLoading(false)
            return menuData;
        }
        if(restaurantId !== parent_restaurant_id) {
            getMenusData().catch((error)=>{
                console.log(error);
            });
        }
        return () => mounted = false;
    }, []);

    useEffect(() => {
        setLogoPreview(previewUrl)
    }, [previewUrl])

    useEffect(() => {
        return () => dispatch(hideSnackbarError());
    },[])

    function handleDisabledChange() {
        dispatch(setMenuDisabled(!disabled))
    }

    const setLogoImage = (imageFile) => {
        const {preview, ...imageObject} = imageFile;
        setLogoPreview(preview);
        dispatch(addPreview(imageObject));
        dispatch(hideSnackbarError());
    }

    const deleteLogoImage = () => {
        setLogoPreview('');
        dispatch(deleteLogoPreview())
    }

    return (
            <div className='dashboard__content-2-col'>
                <div className='dashboard__content-main'>
                    <section className='dashboard-section'>
                        <h2 className='dashboard-section__title'>
                            {t("menu.preview.title")} <HelpTooltip content={<div><h4>{t('prompt')}</h4><p>{t('menu.preview.tooltip')}</p></div>} />
                        </h2>
                        {logoPreview ? <LogoPreview preview={logoPreview} deletePreview={deleteLogoImage}/>
                            : <ImageDropZoneInput
                                chooseImg={setLogoImage}
                                setInvalidImgMessage={(messageKey) => dispatch(showSnackbarError(messageKey))}
                                invalidInput={isImageInvalid}
                            />
                        }
                    </section>
                    <section className='dashboard-section dashboard__section-menu'>
                        <h2 className='dashboard-section__title'>
                            {t("menu.menu.title")} <HelpTooltip content={<div><h4>{t('prompt')}</h4><p> {t("menu.menu.tooltip")} </p></div>} />
                        </h2>
                        <DishCategories categories={categories}/>
                        <DishList categories={categories} activeCategoryId={activeCategoryId} />
                        <Button disabled={categories.length <= 1} onClick={() => setModal(<AddDish activeCategoryId={activeCategoryId}/>)}>{t('menu.menu.addmenu')}</Button>
                    </section>
                </div>
                <div className='dashboard__content-aside'>
                    <section className='dashboard-section dashboard-section--sidebar'>
                        <h2 className='dashboard-section__title dashboard-section__title-sidebar'>{t('publishSection.title')}</h2>
                        <div className='dashboard-section__text'>
                            <p>{t('publishSection.text')}</p>
                        </div>
                        <div className='menu-visibility'>
                            <span className='menu-visibility__label'>
                                 <Button icon rounded onClick={()=>{
                                     window.open(menuOrryUrl, '_blank');
                                 }}>
                                    <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.8401 4.53062C17.8401 4.53062 14.0965 0 9.00002 0C3.9038 0 0.159984 4.53062 0.159984 4.53062C-0.0533279 4.78875 -0.0533279 5.21125 0.159984 5.46969C0.159984 5.46937 3.9038 10 9.00002 10C14.0962 10 17.8397 5.46937 17.8397 5.46937C18.0534 5.21125 18.0534 4.78875 17.8401 4.53062ZM9.00002 8.84906C6.91832 8.84906 5.23049 7.12594 5.23049 5C5.23049 2.87406 6.91832 1.15094 9.00002 1.15094C11.0817 1.15094 12.7695 2.87437 12.7695 5C12.7695 7.12563 11.082 8.84906 9.00002 8.84906Z" fill="white"/>
                                        <path d="M10.7779 4.19423C10.3351 4.19423 9.9758 3.82767 9.9758 3.37517C9.9758 3.25704 10.0006 3.14454 10.0447 3.04329C9.73433 2.87017 9.3784 2.77173 9.00014 2.77173C7.79525 2.77173 6.81836 3.76923 6.81836 4.99954C6.81836 6.22985 7.79525 7.22767 9.00014 7.22767C10.205 7.22767 11.1819 6.23017 11.1819 4.99985C11.1819 4.70142 11.1241 4.41673 11.0197 4.15642C10.9435 4.18079 10.8621 4.19423 10.7779 4.19423Z" fill="white"/>
                                    </svg>
                                </Button>
                                {t('publishSection.visibility')}
                            </span>
                            <Toggle checked={!disabled} onChange={handleDisabledChange}/>
                        </div>
                    </section>
                    <section className='dashboard-section dashboard-section--sidebar'>
                        <QrCode menuUrl={menuOrryUrl}/>
                    </section>
                </div>
            </div>
        );
}
function SectionStyle() {
    return (
        <section className='dashboard-section dashboard-section--sidebar'>
            <h2 className='dashboard-section__title dashboard-section__title-sidebar'>Настройка стиля</h2>
            <div className='dashboard-section__text'>
                <p>Здесь вы можете управлять  видимостью вашего меню для пользвателей.</p>
            </div>
            <Button transparent>Настроить</Button>
        </section>
    );
}
function SectionPremium() {
    return (
        <section className='dashboard-section dashboard-section--sidebar'>
            <h2 className='dashboard-section__title dashboard-section__title-sidebar'>Премиум подписка</h2>
            <div className='dashboard-section__text'>
                <p>Скоро вы сможете перейти на премиум подписку</p>
            </div>
            <Button accent>Премиум</Button>
        </section>
    );
}
const mapStateToProps = state => {
    const isImageErrorKey = IMAGE_UPLOAD_ERROR_MESSAGE_KEYS.includes(get(state, 'general.errorTextKey'));

    return {
        restaurantId: get(state,'restaurants.activeRestaurant.id'),
        menu: get(state,'menu.menu'),
        isImageInvalid: isImageErrorKey
    };
};

export default connect(mapStateToProps)(AddMenu);
