import {HIDE_SNACKBAR_ERROR, RESET_STORE, SHOW_SNACKBAR_ERROR} from "../types";

export const showSnackbarError = (errorTextKey) => dispatch => {
    dispatch({
        type: SHOW_SNACKBAR_ERROR,
        payload: {errorTextKey}
    })
}

export const hideSnackbarError = () => dispatch => {
    dispatch({
        type: HIDE_SNACKBAR_ERROR
    })
}

export const resetStore = () => dispatch => {
    dispatch({
        type: RESET_STORE
    })
}
