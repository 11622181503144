import React, { createContext, useState, useContext, useEffect } from "react";
import { v4 } from "uuid";
import {getMenu} from "../services/menusService/menu.service";
const MenuContext = createContext();
export const useMenus = () => useContext(MenuContext);

function updateObjectInArray(array, arrayIndex, newItem) {
    return array.map((item, index) => {
        if (index !== arrayIndex) {
            // This isn't the item we care about - keep it as-is
            return item
        }
        // Otherwise, this is the one we want - return an updated value
        return {
            ...item,
            ...newItem
        }
    })
}

export function MenuProvider ({ children }) {
    const initMenu = {
        "id": v4(),
        "title": "",
        "categories": [
            {
                "id": "0",
                "button": true
            }
        ]
    }
    const [menus, setMenus] = useState(initMenu);
    const [loading, setLoading] = useState(false);

    const getMenusData = async ()=>{
        setLoading(true)
        const menuData = await getMenu();
        console.log('getMenusData', menuData[0]);
        setMenus({
            ...menus,
            categories: menuData[0].categories
        })
        setLoading(false)
    }

    let activeDefaultCategory = ''; //menus.categories.filter(cat => cat.home);
    let activeDefaultCategoryTest = '';

    const [activeCategoryId, setActiveCategoryId] = useState(activeDefaultCategoryTest);

    if(activeDefaultCategory.length) {
        // setActiveCategoryId(activeDefaultCategory[0].id);
    }
    const addCategory = (name, home) => {
        const catId = v4();
        setMenus({
            ...menus,
            categories: [...menus['categories'], {
                id: catId,
                name: name,
                home: home,
                dishes: []
            }]
        });

        if (!activeCategoryId) {
            setActiveCategoryId(catId);
        }

    };
    const addDish = (name, ingredients, weight, price) => {
        let activeDishCategory = menus.categories.filter(cat => cat.id === activeCategoryId);
        let objIndex = 1;

        if (menus.categories.length) {
             objIndex = menus.categories.findIndex(
                 (
                     obj => obj.id == activeDishCategory[0].id
                 ));
        }

        const updatedDishes = [...activeDishCategory[0].dishes, {
            id: v4(), name, ingredients, weight, price
        }];

        menus.categories[objIndex].dishes = updatedDishes;

        setMenus({
            ...menus,
            categories: menus.categories
        })
    }
    const updateDish = (id, name, ingredients, weight, price) => {
        let activeDishCategory = menus.categories.filter(cat => cat.id === activeCategoryId);
        const objIndex = menus.categories.findIndex((obj => obj.id == activeDishCategory[0].id));
        const dishIndex = activeDishCategory[0].dishes.findIndex((dishObj => dishObj.id == id));
        let updatedDishes = updateObjectInArray(activeDishCategory[0].dishes, dishIndex, {id, name, ingredients, weight, price});
        menus.categories[objIndex].dishes = updatedDishes;
        setMenus({
            ...menus,
            categories: menus.categories
        })
    }
    const sortCategories = (sortedCategories) => {
        setMenus({
            ...menus,
            categories: sortedCategories
        })
    }
    const getDishes = () => {
        if (!menus) {
            return [];
        }
        console.log(activeCategoryId);
        let activeCategory = menus.categories.filter(cat => cat.id === activeCategoryId);
        console.log('getDishes activeCategory', activeCategory);
        if (activeCategory.length) {
            return activeCategory[0].dishes;
        }

        return [];
    };
    return (
        <MenuContext.Provider value={{
            menus,
            setMenus,
            activeCategoryId,
            setActiveCategoryId,
            addCategory,
            sortCategories,
            getDishes,
            addDish,
            updateDish
        }}>
            {children}
        </MenuContext.Provider>
    );
};
