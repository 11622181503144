import React from 'react';
import './Dashboard.css';
import { withRouter } from "react-router-dom";
import {isMobile} from 'react-device-detect';
import NavigationDropdown from "../base-components/NavigationDropdown/NavigationDropdown";
import Menu from "../Menu/Menu";
import DashboardContent from "./DashboardContent";
import UserOverview from "../UserOverview";
import MobileView from  "../MobileView";
import {connect} from "react-redux";
import {resetStore} from "../../store/general/actions";
import {getRestaurants} from "../../services/restaurantsService/restaurant.service";
import {getCurrentUser} from "../../services/userService/user.service";

class Dashboard extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { user: { }, restaurants: {} };
        this.Logout = this.Logout.bind(this);
        this.getCurrentUser = this.getCurrentUser.bind(this);
    }

    componentDidMount() {
        this.getCurrentUser();
        this.loadRestaurants();
    }

    async getCurrentUser() {
        const _this = this;

        try {
            const currentUser = await getCurrentUser();
            _this.setState( {user: currentUser});
        } catch (e) {
            _this.Logout();
        }
    }
    async loadRestaurants() {
        const token = localStorage.getItem('login');
        if(!token) {
            this.Logout();
        }
        const _this = this;
        try {
           const restaurants = await getRestaurants();
            _this.setState( {restaurants});
        } catch (e) {
            //TODO need to handle error from request
        }
    }

    Logout() {
        localStorage.removeItem('login');
        this.props.history.push("/login");
        this.props.resetStore();
    }

    render() {
        const { restaurants } = this.state;

        return (
            <>
                {isMobile && <MobileView />}
                {!isMobile &&
                    <div className="dashboard">
                        <div className="dashboard__sidebar">
                            <div className="dashboard-logo">
                                <svg width="83" height="16" viewBox="0 0 83 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="7.5" y="14.5" width="13" height="21" rx="6.5" transform="rotate(-90 7.5 14.5)" stroke="#4F4C60" strokeWidth="3"/>
                                    <rect x="7.5" y="14.5" width="13" height="13" rx="6.5" transform="rotate(-90 7.5 14.5)" stroke="white" strokeWidth="3"/>
                                    <path d="M33 16H36.4V10.5778H39.5943C39.96 10.5778 40.2966 10.7774 40.4719 11.0983L43.15 16H47L44.2899 11.0524C44.0025 10.5276 44.2472 9.87928 44.7422 9.54304C45.2779 9.17916 45.7013 8.72777 46.0125 8.18889C46.5042 7.34444 46.75 6.37778 46.75 5.28889C46.75 3.94074 46.3792 2.79259 45.6375 1.84444C44.8958 0.896296 43.7167 0.311111 42.1 0.0888893C41.8667 0.0518523 41.6 0.0296299 41.3 0.0222221C41.0083 0.0074075 40.775 0 40.6 0H33V16ZM36.4 7.75556V2.82222H40.45C40.625 2.82222 40.8167 2.82963 41.025 2.84444C41.2333 2.85926 41.425 2.88889 41.6 2.93333C42.0583 3.04445 42.4125 3.23333 42.6625 3.5C42.9125 3.75926 43.0833 4.05185 43.175 4.37778C43.275 4.6963 43.325 5 43.325 5.28889C43.325 5.57778 43.275 5.88519 43.175 6.21111C43.0833 6.52963 42.9125 6.82222 42.6625 7.08889C42.4125 7.34815 42.0583 7.53333 41.6 7.64445C41.425 7.68889 41.2333 7.71852 41.025 7.73333C40.8167 7.74815 40.625 7.75556 40.45 7.75556H36.4Z" fill="white"/>
                                    <path d="M50 16H53.4V10.5778H56.5943C56.96 10.5778 57.2966 10.7774 57.4719 11.0983L60.15 16H64L61.2899 11.0524C61.0025 10.5276 61.2472 9.87928 61.7422 9.54304C62.2779 9.17916 62.7013 8.72777 63.0125 8.18889C63.5042 7.34444 63.75 6.37778 63.75 5.28889C63.75 3.94074 63.3792 2.79259 62.6375 1.84444C61.8958 0.896296 60.7167 0.311111 59.1 0.0888893C58.8667 0.0518523 58.6 0.0296299 58.3 0.0222221C58.0083 0.0074075 57.775 0 57.6 0H50V16ZM53.4 7.75556V2.82222H57.45C57.625 2.82222 57.8167 2.82963 58.025 2.84444C58.2333 2.85926 58.425 2.88889 58.6 2.93333C59.0583 3.04445 59.4125 3.23333 59.6625 3.5C59.9125 3.75926 60.0833 4.05185 60.175 4.37778C60.275 4.6963 60.325 5 60.325 5.28889C60.325 5.57778 60.275 5.88519 60.175 6.21111C60.0833 6.52963 59.9125 6.82222 59.6625 7.08889C59.4125 7.34815 59.0583 7.53333 58.6 7.64445C58.425 7.68889 58.2333 7.71852 58.025 7.73333C57.8167 7.74815 57.625 7.75556 57.45 7.75556H53.4Z" fill="white"/>
                                    <path d="M72.0286 16H75.9714V9.48889L83 0H78.9611C78.6441 0 78.3459 0.150297 78.1573 0.405097L74.8038 4.93618C74.404 5.47631 73.596 5.47631 73.1962 4.93618L69.8427 0.405097C69.6541 0.150297 69.3559 0 69.0389 0H65L72.0286 9.48889V16Z" fill="white"/>
                                    <circle cx="4" cy="8" r="4" fill="#4F4C60"/>
                                </svg>
                            </div>
                            <NavigationDropdown />
                            <Menu />
                            <UserOverview logout={this.Logout}/>
                        </div>
                        <DashboardContent restaurants={restaurants}/>
                    </div>
                }
            </>
        );
    }
}
const mapDispatchToProps = dispatch => ({
    resetStore: () => {
        dispatch(resetStore())
    }
});

export default withRouter(connect(null, mapDispatchToProps)(Dashboard));
