import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {get} from 'lodash';
import {Link} from "react-router-dom";
import ActionsButton from '../base-components/ActionsButton/ActionsButton';
import {getRestaurants, deleteRestaurant} from "../../services/restaurantsService/restaurant.service";
import {loadRestaurants, setActiveRestaurant} from "../../store/restaurants/actions";
import {loadUserData} from "../../store/user/actions";
import {removeRestaurant} from "../../store/restaurants/actions";
import Button from "../base-components/Button";
import {useModal} from "../../context/model-context";
import ConfirmationForm from "../ConfirmationForm";
import {useTranslation} from "react-i18next";
function RestaurantList(props) {
    const dispatch = useDispatch();
    const { setModal } = useModal();
    const {t} = useTranslation('common');
    const [loading, setLoading] = useState(false);
    const {restaurants, url, path, activeRestaurant} = props;
    useEffect(() => {
        let mounted = true;
        const getRestaurantsData = async () => {
            setLoading(true)
            const restaurantsData = await getRestaurants();
            if (mounted) {
                props.loadRestaurantsData(restaurantsData);
            }
            setLoading(false);
        }
        if (restaurants.length === 0) {
            getRestaurantsData().catch((error)=>{
                console.log(error);
            });
        }
        return () => mounted = false;
    }, []);

    const deleteRestaurantById = async (restaurantId) => {
        const response = await deleteRestaurant(restaurantId);
        return response;
    }
    return (
        <div className="restaurants-list">
            {restaurants && restaurants.map((restaurant, index) => {
                let activeClass = '';
                if(Object.keys(activeRestaurant).length !== 0 && activeRestaurant.constructor === Object) {
                     activeClass = activeRestaurant.id === restaurant.id ? 'restaurant-card--active' : '';
                }
                return (
                    <div key={restaurant.id} id={restaurant.id} className={`restaurant-card ${activeClass}`} onClick={()=> props.setActiveRestaurant(restaurant)}>
                        <h2 className="restaurant-card__name">{restaurant.title}</h2>
                        <span className="restaurant-card__plan-status">{t('restaurant.freeplan')}</span>
                        <ActionsButton classes='edit-restaurant-action' fill='#FBFBFB'>
                            <Link to={`${path}/restaurant/edit/${restaurant.id}`}>{t('edit')}</Link>
                            <Button onClick={() => setModal(
                                <ConfirmationForm
                                title={t('restaurant.sureToDelete')}
                                handleCancelClick={() => setModal()}
                                handleOkClick={() => {
                                    deleteRestaurantById(restaurant.id).then((response) => {
                                        if(response.status == 'success') {
                                            dispatch(removeRestaurant(restaurant.id));
                                            setModal();
                                        }
                                    }).catch((error)=>{
                                        console.log(error);
                                    });
                                }}/>
                            )}>{t('delete')}</Button>
                        </ActionsButton>
                    </div>
                );
            })}
            <Link to={`${path}/restaurant/new`}>
                <div className="restaurant-card restaurant-card__add">
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="9" width="3" height="21" rx="1.5" fill="#363347"/>
                        <rect y="12" width="3" height="21" rx="1.5" transform="rotate(-90 0 12)" fill="#363347"/>
                    </svg>
                </div>
            </Link>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        restaurants: get(state,'restaurants.restaurants'),
        activeRestaurant: get(state, 'restaurants.activeRestaurant')
    };
};
const mapDispatchToProps = dispatch => ({
    loadRestaurantsData: restaurants => {
        dispatch(loadRestaurants(restaurants));
    },
    setActiveRestaurant: restaurant => {
        dispatch(setActiveRestaurant(restaurant));
    },
    loadUserData: userData => {
        dispatch(loadUserData(userData));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantList);
